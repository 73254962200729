import { Input } from '@chakra-ui/react';

import { OrderSummaryResponseType } from './use-add-order';

interface OrderSummaryProps {
  orderSummary?: OrderSummaryResponseType;
  date: { startDate: string; endDate: string };
}

export const OrderSummary = ({ orderSummary, date }: OrderSummaryProps) => {
  return (
    <>
      <div className="flex flex-col gap-spacing-md">
        <div className="flex basis-1/2 gap-spacing-xl">
          <div className="flex flex-col gap-spacing-xs">
            <label className="text-sm font-medium text-grayLight-700">Data od</label>
            <Input
              type="date"
              lang="pl"
              required
              name="startDate"
              value={date.startDate}
              disabled
            />
          </div>
          <div className="flex flex-col gap-spacing-xs">
            <label className="text-sm font-medium text-grayLight-700">Data do</label>
            <Input type="date" lang="pl" name="endDate" value={date.endDate} disabled />
          </div>
        </div>
      </div>
      {!!orderSummary?.success && (
        <div className="flex flex-col gap-spacing-md">
          <div>
            <p className="mt-2 text-sm font-medium text-grayLight-700">Wybrane stawki:</p>
          </div>
          <div>
            <table className="w-full border-collapse">
              <thead className="text-xs bg-gray-50">
                <tr>
                  <th className="w-1/2 border-gray-300 p-2 text-left text-gray-600">
                    <p>Nazwa stawki</p>
                  </th>
                  <th className="w-[110px] border-l border-r border-gray-300 p-2 text-gray-600 text-left">
                    <p>Liczba konsumentów</p>
                  </th>
                  <th className="w-[110px] border-l border-r border-gray-300 p-2 text-gray-600 text-left">
                    <p>Ilość na osobę / dzień</p>
                  </th>
                  <th className="w-[110px] border-l border-r border-gray-300 p-2 text-gray-600 text-left">
                    <p>Liczba dni</p>
                  </th>
                  <th className="w-[110px] border-l border-r border-gray-300 p-2 text-gray-600 text-left">
                    <p>Łączna ilość</p>
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm font-medium text-grayLight-900">
                {orderSummary?.orderPositions &&
                  orderSummary?.orderPositions.map((orderPosition) => {
                    return (
                      <tr key={orderPosition.stakeName} className={`border-t `}>
                        <td className="w-1/2 text-left pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md font-medium text-grayLight-900">
                          {orderPosition.stakeName}
                        </td>
                        <td className="w-[110px] pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md border-l border-r text-left">
                          {orderPosition.consumerCount}
                        </td>
                        <td className="w-[110px] pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md border-l border-r text-left">
                          {orderPosition.countPerDay}
                        </td>
                        <td className="w-[110px] pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md border-l border-r text-left">
                          {orderPosition.dayCount}
                        </td>
                        <td className="w-[110px] pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md border-l border-r text-left">
                          {orderPosition.together}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};
