import React from 'react';
import IconComponent from '../IconComponent/IconComponent';

const ErrorBox = ({ message }: { message: string | string[] }) => {
  return (
    <div className="flex flex-col gap-spacing-md bg-brand-100 border border-grayLight-300 rounded-xl p-spacing-xl">
      <div className="flex items-start gap-spacing-xl">
        <div
          style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/outliner.png)` }}
          className="SettingsGreetings__container flex items-center justify-center relative">
          <IconComponent iconName="info" color="#FFAC05" />
        </div>
        <div className="font-semibold text-sm text-grayWarm-700">Wystąpiły błędy</div>
      </div>
      <div className="max-h-40 overflow-y-auto">
        {Array.isArray(message) ? (
          <ul className="font-normal text-sm text-grayWarm-700 list-disc list-inside">
            {message.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        ) : (
          <p className="font-normal text-sm text-grayWarm-700">{message}</p>
        )}
      </div>
    </div>
  );
};

export default ErrorBox;
