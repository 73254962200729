import { Checkbox, TableContainer, Thead, Tr, Th, Box, Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { AxiosResponse } from "axios";
import React, { useState, useEffect } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { useAlertContext } from "../../../context/AlertProvider";
import { useApi } from "../../../context/ApiProvider";
import { AbsenceInterface } from "../../../shared/type/absence.type";
import { CustomAlertDialogInterface } from "../../../shared/type/CustomAlertDialog.type";
import ChooseMonths from "../../ChooseMonths/ChooseMonths";
import { DataTable } from "../../DataTable/DataTable";

const Absences = () => {
  const location = useLocation();
  const { setAlertProperties, setShow: setShowAlert } = useAlertContext();
  const { id: tenantId } = useParams<{ id: string }>();
  const { apiAbsenceAndCourseController, apiTenantAdminBoughtStakesController } = useApi();
  const columnHelper = createColumnHelper<AbsenceInterface>();

  const today = new Date();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [absencesForDay, setAbsencesForDay] = useState<AbsenceInterface[]>([]);

  const [ currentDate, setCurrentDate] = useState<{ year: number; month: number, day: number }>({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate()
  });

  const getAlertBody = (string: string): CustomAlertDialogInterface => ({
    status: 'error',
    title: 'Błąd',
    description: `Nie udało się zmienić ${string}`,
    timeout: 5000,
  })

  const updateAbsenceLocally = (consumerId: number, boughtStakeId: number[], newPresence: boolean) => {
    setAbsencesForDay(prevAbsences =>
      prevAbsences.map(absence => {
        if (absence.consumerId === consumerId) {
          return {
            ...absence,
            stakesAndCourses: absence.stakesAndCourses.map(stake => {
              if (boughtStakeId.includes(stake.boughtStakeId)) {
                return {
                  ...stake,
                  presence: newPresence,
                };
              }
              return stake;
            }),
          };
        }
        return absence;
      })
    );
  };

  const updateTakeStackLocally = (boughtStakeId: number, courseId: number, newTaken: boolean) => {
    setAbsencesForDay(prevAbsences =>
      prevAbsences.map(absence => ({
        ...absence,
        stakesAndCourses: absence.stakesAndCourses.map(stake => {
          if (stake.boughtStakeId === boughtStakeId) {
            return {
              ...stake,
              takenCourses: stake.takenCourses.map(course => {
                if (course.courseId === courseId) {
                  return {
                    ...course,
                    taken: newTaken,
                  };
                }
                return course;
              }),
            };
          }
          return stake;
        }),
      }))
    );
  };


  const handleAbsence = async (boughtStakesId: number[], absence: boolean, consumerId: number) => {
    updateAbsenceLocally(consumerId, boughtStakesId, absence);
    try {
      await apiAbsenceAndCourseController('report-or-revoke-absence').post('', {
        consumerId,
        boughtStakesId,
        absence: !absence
      });
    } catch (error: any) {
      updateAbsenceLocally(consumerId, boughtStakesId, !absence);
      setAlertProperties(getAlertBody(`obecności: ${error.response.data.errors[0] || error.response.data.errors[0]}`));
      setShowAlert(true);
    }
  };

  const handleTakeStack = async (boughtStakeId: number, courseId: number, taken: boolean) => {
    updateTakeStackLocally(boughtStakeId, courseId, taken);
    try {
      await apiTenantAdminBoughtStakesController('mark-course-as-taken').post('', {
        boughtStakeId,
        courseId,
        taken
      })
    } catch (error: any) {
      updateTakeStackLocally(boughtStakeId, courseId, !taken);
      setAlertProperties(getAlertBody(`odbioru: ${error.response.data.errorMessage}`));
      setShowAlert(true);
    }
  }

  const columns = [
    columnHelper.display({
      id: 'rowNumber',
      cell: (info) => (
        <span>{info.row.index + 1}.</span>
      ),
      header: 'Lp.'
    }),
    columnHelper.accessor('consumerName', {
      cell: (info) => {
        const { consumerName, consumerId } = info.row.original;
        const newPath = location.pathname.replace('absences', `users/children/single-kid/${consumerId}/orders`);

        return (
          <p className='text-grayLight-900 font-medium text-sm underline underline-offset-4'>
            <Link to={newPath}>
              {consumerName}
            </Link>
          </p>
        );
      },
      header: 'Imię i nazwisko'
    }),
    columnHelper.accessor('organisationName', {
      cell: (info) => {
        const { organisationName } = info.row.original;

        return (
          <p className='text-grayLight-900 font-medium text-sm'>
            {organisationName}
          </p>
        );
      },
      header: 'Klasa'
    }),
    columnHelper.accessor('stakesAndCourses', {
      cell: (info) => {
        const stakesAndCourses = info.row.original.stakesAndCourses;

        if(stakesAndCourses.length === 0) {
          return <p className="text-grayLight-900 font-medium text-sm"></p>;
        }

        const totalAssignedCourses = stakesAndCourses.reduce((acc, stake) => {
          return acc + stake.takenCourses.length;
        }, 0);

        return (
          <div className="flex flex-col justify-center " style={{ height: totalAssignedCourses * 50 + 'px' }}>
              {stakesAndCourses.map((item, stakeIndex) => (
                <div key={`stake-${stakeIndex}`}
                     className={`flex flex-col justify-center ${stakeIndex !== stakesAndCourses.length - 1 ? 'border-b' : ''}`}
                     style={{ height: `${item.takenCourses.length/totalAssignedCourses * 100}%`}}>
                  <p className='text-grayLight-900 font-medium text-sm'>{item.stakeName} {item.takenCourses.length}</p>
                </div>
              ))}
            </div>
        );
      },
      header: 'Stawka i kategorie'
    }),
    columnHelper.accessor('stakesAndCourses', {
      cell: (info) => {
        const { consumerId, stakesAndCourses } = info.row.original;

        if(stakesAndCourses.length === 0) {
          return <p className="text-grayLight-900 font-medium text-sm"></p>;
        }

        const totalAssignedCourses = stakesAndCourses.reduce((acc, stake) => {
          return acc + stake.takenCourses.length;
        }, 0);

        return (
          <div className="flex flex-col justify-center" style={{ height: totalAssignedCourses * 50 + 'px' }}>
            {stakesAndCourses.map((item, stakeIndex) => (
              <div key={`stake-${stakeIndex}`}
                   className={`flex flex-col justify-center ${stakeIndex !== stakesAndCourses.length - 1 ? 'border-b' : ''}`}
                   style={{ height: `${item.takenCourses.length/totalAssignedCourses * 100}%`}}>
                <Checkbox
                  colorScheme="customOrange"
                  isChecked={item.presence}
                  onChange={() => {
                    handleAbsence([item.boughtStakeId],  !item.presence, consumerId);
                  }}
                />
              </div>
            ))}
          </div>
        );
      },
      header: 'Obecność'
    }),
    columnHelper.accessor('stakesAndCourses', {
      cell: (info) => {
        const { stakesAndCourses } = info.row.original;

        if(stakesAndCourses.length === 0) {
          return <p className="text-grayLight-900 font-medium text-sm"></p>;
        }

        const totalAssignedCourses = stakesAndCourses.reduce((acc, stake) => {
          return acc + stake.takenCourses.length;
        }, 0);

        return (
          <div className="flex flex-col justify-center bg-red" style={{ height: totalAssignedCourses * 50 + 'px' }}>
            {stakesAndCourses.map((stake, stakeIndex) => (
              <div key={`stake-${stakeIndex}`}
                   className={`flex flex-col justify-center ${stakeIndex !== stakesAndCourses.length - 1 ? 'border-b' : ''}`}
                   style={{ height: stake.takenCourses.length / totalAssignedCourses * 100 + "%"}}>
                {
                  stake.takenCourses.map(course => (
                    <div className='flex flex-col justify-center' style={{ height: 1 / stakesAndCourses.length * 100 + "%" }}>
                      <p className="text-grayLight-900 font-medium text-sm">{course.courseName}</p>
                    </div>
                  ))
                }
              </div>
            ))}
          </div>
        );
      },
      header: "Kategorie posiłków"
    }),
    columnHelper.accessor('stakesAndCourses', {
      cell: (info) => {
        const { stakesAndCourses } = info.row.original;

        console.log(stakesAndCourses)

        if(stakesAndCourses.length === 0) {
          return <p className="text-grayLight-900 font-medium text-sm"></p>;
        }

        const totalAssignedCourses = stakesAndCourses.reduce((acc, stake) => {
          return acc + stake.takenCourses.length;
        }, 0);

        return (
          <div className="flex flex-col justify-center" style={{ height: totalAssignedCourses * 50 + 'px' }}>
            {stakesAndCourses.map((stake, stakeIndex) => (
              <div key={`stake-${stakeIndex}`}
                   className={`flex flex-col justify-center ${stakeIndex !== stakesAndCourses.length - 1 ? 'border-b' : ''}`}
                   style={{ height: stake.takenCourses.length / totalAssignedCourses * 100 + "%"}}>
                {
                  stake.takenCourses.map(course => (
                    <div className='flex flex-col justify-center items-center' style={{ height: 1 / stakesAndCourses.length * 100 + "%" }}>
                      <Checkbox
                        colorScheme="customOrange"
                        isChecked={course.taken}
                        onChange={() => {
                          handleTakeStack(stake.boughtStakeId, course.courseId, !course.taken);
                        }}
                      />
                    </div>
                  ))
                }
              </div>
            ))}
          </div>
        );
      },
      header: "Odbiór"
    })
  ];

  const fetchAbsences = async () => {
    try {
      setIsLoading(true);

      const formattedMonth = String(currentDate.month).padStart(2, "0");
      const formattedDay = String(currentDate.day).padStart(2, "0");

      const response: AxiosResponse<AbsenceInterface[]> = await apiAbsenceAndCourseController("all-absences-for-day").post("",
        {
          tenantId,
          forWhen: `${currentDate.year}-${formattedMonth}-${formattedDay}`,
        });

      setAbsencesForDay(response.data);
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAbsences();
  }, [tenantId, currentDate]);

  return (
    <section className="Absences p-spacing-xl w-full">
      <TableContainer h={800} overflowY="auto" className="Branches__table bg-white rounded-lg">
        <DataTable
          disableFilters={true}
          filterComponent={
            <div className='flex gap-spacing-md'>
              <ChooseMonths mode='day' currentDate={currentDate} setDate={setCurrentDate} />
            </div>
          }
          extraThead={
            <Thead>
              <Tr>
                <Th colSpan={columns.length + 1}>
                  <div className="w-full flex items-center justify-between">
                    <div className="flex flex-col basis-4/5">
                      <Box>
                        <Text
                          whiteSpace="normal"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          className="font-semibold text-base text-grayLight-900"
                        >
                          Zamówienia
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          whiteSpace="normal"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          className="font-normal text-sm text-grayLight-700"
                        >
                          Tutaj możesz oznaczyć obecności i nieobecności użytkowników a także odebrane i nieodebrane posiłki.
                        </Text>
                      </Box>
                    </div>
                    <div className="flex gap-spacing-lg">
                    </div>
                  </div>
                </Th>
              </Tr>
            </Thead>
          }
          columns={columns}
          isLoading={isLoading}
          data={absencesForDay}
        />
      </TableContainer>
    </section>
  )
}

export default Absences;
