import {
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import { useCallback } from 'react';

import CustomChakraButton from '../../../../../CustomChakraButton/CustomChakraButton';
import { AddOrderForm } from './AddOrderForm';
import { OrderSummary } from './OrderSummary';
import { useAddOrder } from './use-add-order';
import ErrorBox from '../../../../../ErrorBox/ErrorBox';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  consumerIds: number[];
  afterAction?: () => void;
}

const AddOrder = ({ isOpen, handleClose, consumerIds, afterAction }: Props) => {
  const {
    massOrderContext,
    stakesCount,
    date,
    setDate,
    handleMassOrder,
    changeStackCountValue,
    formErrors,
    showFormErrors,
    orderSummary,
    currentStep
  } = useAddOrder({
    consumerIds,
    handleClose,
    afterAction
  });
  const isAddOrderStep = currentStep === 'add-order';

  const getModalContentForStep = useCallback(() => {
    if (!massOrderContext) return null;

    switch (currentStep) {
      case 'add-order':
        return (
          <AddOrderForm
            date={date}
            massOrderContext={massOrderContext}
            stakesCount={stakesCount}
            changeStackCountValue={changeStackCountValue}
            setDate={setDate}
          />
        );
      case 'order-summary':
        return <OrderSummary orderSummary={orderSummary} date={date} />;
    }
  }, [
    currentStep,
    date,
    massOrderContext,
    stakesCount,
    changeStackCountValue,
    setDate,
    handleMassOrder,
    consumerIds
  ]);

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleClose} size="4xl" isCentered>
      <ModalOverlay
        sx={{
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }}
      />
      <ModalContent>
        <ModalHeader className="flex flex-col">
          <h2 className="font-semibold text-lg">
            {currentStep === 'add-order' ? 'Dodaj zamówienie' : 'Podsumowanie zamówienia'}
          </h2>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col gap-spacing-3xl">{getModalContentForStep()}</ModalBody>
        {showFormErrors && formErrors.length > 0 && (
          <div className="w-full px-8 my-2">
            <ErrorBox message={formErrors} />
          </div>
        )}
        <Divider className="pt-spacing-xl pb-spacing-xl" />
        <ModalFooter className="w-full flex justify-between gap-spacing-lg">
          {isAddOrderStep && (
            <>
              <CustomChakraButton
                hierarchy="secondaryColor"
                size="lg"
                iconPosition="onlyText"
                buttonProps={{
                  className: 'grow',
                  onClick: handleClose
                }}>
                Zamknij
              </CustomChakraButton>

              <CustomChakraButton
                hierarchy="primary"
                size="lg"
                iconPosition="onlyText"
                buttonProps={{
                  className: 'grow',
                  type: 'submit',
                  onClick: handleMassOrder,
                  disabled: showFormErrors && formErrors.length > 0
                }}>
                {'Złóż zamówienie'}
              </CustomChakraButton>
            </>
          )}
          {!isAddOrderStep && (
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: 'grow',
                type: 'button',
                onClick: () => {
                  handleClose();
                  afterAction?.();
                }
              }}>
              Zamknij
            </CustomChakraButton>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddOrder;
