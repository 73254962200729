import { Select } from 'chakra-react-select';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
// @ts-ignore
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import { useParentBasketContext } from '../../../../context/ParentBasketProvider';
import { useParentDashboardContextContext } from '../../../../context/ParentDashboardContext';
import { useParentOrderContextContext } from '../../../../context/ParentOrderContext';
import { useThemeContext } from '../../../../context/ThemeProvider';
import { CalendarEventsInterface } from '../../../../shared/type/calendarEvents.type';
import { PurchasableItemInterface } from '../../../../shared/type/parentOrderContext.type';
import {
  handleSelectSlot,
  toggleSelectAllDaysInMonth,
  eventPropGetter,
  dayPropGetter,
  getAvailableMeals,
  addOrderToBasket
} from '../../../../utils/calendarOrderHelper';
import handleNavigate from '../../../../utils/calendarOrderHelper/handleNavigate';
import CustomChakraButton from '../../../CustomChakraButton/CustomChakraButton';
import CustomHorizontalTabs from '../../../CustomHorizontalTabs/CustomHorizontalTabs';
import CustomDayCell from './CustomDayCell/CustomDayCell';
import CustomToolbar from './CustomToolbar/CustomToolbar';
import 'moment/locale/pl';

import './OrderOnPeriodOfTime.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const OrderOnPeriodOfTime = () => {
  const { setBasketStatus, parentDashboardContext, fetchParentDashboardContext } =
    useParentDashboardContextContext();
  const { parentOrderContext, setParentOrderContextDate, tenantId, isParentOrderLoading, setSkipFetchingParentOrderContext } =
    useParentOrderContextContext();
  const { addItemToBasket, parentBasketContext } = useParentBasketContext();
  const { isMobile } = useThemeContext();

  const [selectedStake, setSelectedStake] = useState<number>();

  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [selectAllDaysInMonth, setSelectAllDaysInMonth] = useState(false);
  const [viewDate, setViewDate] = useState(new Date());
  const [selectedConsumerId, setSelectedUserId] = useState<number>();
  const [commonMeal, setCommonMeals] = useState<PurchasableItemInterface[]>([]);

  const [fixedEvents, setFixedEvents] = useState<CalendarEventsInterface[]>([]);

  const clearSelectedDates = () => {
    setSelectedDates([]);
  };

  function generateFixedEvents() {
    const consumerOrderContext = parentOrderContext?.consumerOrderContexts.find(
      (consumer) => consumer.consumerId === selectedConsumerId
    );
    if (!consumerOrderContext) {
      setFixedEvents([]);
      return;
    }

    const boughtItems = consumerOrderContext.orderDays.flatMap((day) => {
      return (day.purchasableItems || [])
        .filter((item) => item.alreadyBoughtCount > 0)
        .map((item) => ({
          title: (
            <>
              <p
                className={`font-medium ${isMobile && 'bg-orange-50 rounded box-border border font-medium border-orange-200 text-orange-700 p-spacing-xxs '} text-xs text-grayBlue-700`}>
                {isMobile ? `${item.name}` : `${item.name} (${item.alreadyBoughtCount} szt.)`}
              </p>
            </>
          ),
          start: moment(day.when, 'YYYY-MM-DD').startOf('day').toDate(),
          end: moment(day.when, 'YYYY-MM-DD').endOf('day').toDate(),
          purchasableItemId: item.stakeId,
          isFixed: true,
          consumerId: selectedConsumerId
        }));
    });

    const holidays = consumerOrderContext.orderDays
      .filter((day) => !day.workingDay && !day.salesBlocked && !!day.comment.length)
      .map((day) => {
        return {
          title: (
            <>
              <p
                className={`font-medium ${isMobile && 'bg-orange-50 rounded box-border border font-medium border-orange-200 text-orange-700 p-spacing-xxs '} text-xs text-grayBlue-700`}>
                {day.comment}
              </p>
            </>
          ),
          start: moment(day.when, 'YYYY-MM-DD').startOf('day').toDate(),
          end: moment(day.when, 'YYYY-MM-DD').endOf('day').toDate(),
          isFixed: true,
          consumerId: selectedConsumerId,
          workingDay: day.workingDay
        };
      });
    setFixedEvents([...boughtItems, ...holidays]);
  }

  const basketItems = useMemo(() => {
    if (!parentBasketContext?.ordersForChildren) {
      return [];
    }

    return parentBasketContext.ordersForChildren
      .filter((orderForChild) => orderForChild.consumerId === selectedConsumerId)
      .flatMap((orderForChild) =>
        orderForChild.orderedItems.map((orderedItem) => ({
          title: (
            <>
              <p
                className={`font-medium ${isMobile && 'bg-grayLight-50 rounded box-border border font-medium border-grayLight-200 text-grayLight-700 p-spacing-xxs '} text-xs text-grayBlue-700`}>
                {isMobile
                  ? `${orderedItem.purchasableItem.name}`
                  : `${orderedItem.purchasableItem.name} (${orderedItem.count} szt.)`}
              </p>
            </>
          ),
          start: moment(orderedItem.when, 'YYYY-MM-DD').startOf('day').toDate(),
          end: moment(orderedItem.when, 'YYYY-MM-DD').endOf('day').toDate(),
          isInBasket: true
        }))
      );
  }, [parentBasketContext, selectedConsumerId]);

  moment.locale('pl');
  const localizer = momentLocalizer(moment);

  const views = useMemo(() => [Views.MONTH], []);
  const minDate = moment(parentOrderContext?.minOrderDate);
  const maxDate = moment(parentOrderContext?.maxOrderDate);

  useEffect(() => {
    if (!isMobile) {
      setBasketStatus({
        forceShow: true,
        show: true
      });
    }
  }, [setBasketStatus]);

  const customHandleNavigate = (action: Date | 'PREV' | 'NEXT' | 'TODAY') => {
    let newDate;

    if (action === 'PREV') {
      newDate = moment(viewDate).subtract(1, 'month');
    } else if (action === 'NEXT') {
      newDate = moment(viewDate).add(1, 'month');
    } else if (action === 'TODAY') {
      newDate = moment();
    } else {
      newDate = moment(action);
    }

    if (newDate.isBefore(minDate, 'month') || newDate.isAfter(maxDate, 'month')) {
      return;
    }

    setSelectedDates([]);

    setViewDate(newDate.toDate());

    setParentOrderContextDate({
      year: newDate.year(),
      month: newDate.month() + 1
    });
  };

  const events = useMemo(() => {
    const combinedEvents = [
      ...fixedEvents.filter((event) => event.consumerId === selectedConsumerId),
      ...basketItems,
      ...selectedDates.map((date) => ({
        start: date,
        end: date,
        isInBasket: false,
        isFixed: false
      }))
    ];

    return combinedEvents;
  }, [fixedEvents, basketItems, selectedDates, selectedConsumerId]);

  useEffect(() => {
    setSkipFetchingParentOrderContext(false);

    return () => {
      setSkipFetchingParentOrderContext(true);
    };
  }, []);

  useEffect(() => {
    if (parentOrderContext?.minOrderDate && new Date(parentOrderContext?.minOrderDate) > viewDate) {
      setViewDate(new Date(parentOrderContext?.minOrderDate));
    }
  }, []);

  useEffect(() => {
    if (!selectedConsumerId && parentDashboardContext) {
      const firstConsumer = parentDashboardContext.tenants
        .flatMap((tenant) => tenant.consumers)
        .map((consumer) => consumer.consumerDto.id)[0];
      setSelectedUserId(firstConsumer);
    }
    if (!viewDate) {
      setViewDate(new Date());
    }
  }, [parentDashboardContext, selectedConsumerId]);

  useEffect(() => {
    if (!parentDashboardContext) {
      fetchParentDashboardContext();
    }
  }, [viewDate, parentDashboardContext]);

  useEffect(() => {
    setCommonMeals(getAvailableMeals(parentOrderContext, selectedConsumerId, selectedDates));
  }, [selectedDates]);

  useEffect(() => {
    generateFixedEvents();
  }, [selectedConsumerId, parentOrderContext, parentDashboardContext]);

  const purchasableItem = commonMeal
    .filter((meal) => !meal.stakeLimitLeft || meal.stakeLimitLeft > 0)
    .filter((meal) => !meal.itemPurchaseLimit || meal.itemPurchaseLimit !== meal.alreadyBoughtCount)
    .map((meal) => ({
      label: meal.name,
      value: meal.stakeId
    }));

  return (
    <section
      style={{
        paddingBottom: isMobile ? '120px' : 0
      }}
      className="OrderOnPeriodOfTime overflow-hidden w-full h-full flex bg-grayLight-50">
      <div className="basis-full overflow-y-scroll md:w-full flex flex-col gap-spacing-5xl lg:pt-spacing-md lg:px-spacing-3xl pb-spacing-3xl">
        <div className="flex flex-col gap-spacing-4xl">
          <div className="flex flex-col px-spacing-xl lg:px-spacing-0 gap-spacing-lg">
            <p className="font-semibold text-2xl text-grayLight-900">Wybierz posiłek i daty</p>
            <p className="font-normal text-grayLight-600" style={{ fontSize: '16px' }}>
              Wybierz rodzaj posiłku oraz dni w jakie dziecko ma otrzymać posiłek, następnie dodaj
              je do koszyka.
            </p>
          </div>
          <div>
            <CustomHorizontalTabs
              onChange={(value) => {
                setSelectedUserId(value);
              }}
              data={
                parentDashboardContext
                  ? Array.from(
                      new Map(
                        parentDashboardContext.tenants
                          .flatMap((tenant) => tenant.consumers)
                          .map((consumer) => [
                            consumer.consumerDto.id,
                            {
                              label: isMobile
                                ? `${consumer.consumerDto.firstName}`
                                : `${consumer.consumerDto.firstName} ${consumer.consumerDto.lastName}`,
                              value: consumer.consumerDto.id
                            }
                          ])
                      ).values()
                    )
                  : null
              }
            />
          </div>
        </div>
        <div className="OrderOnPeriodOfTime__selectServing px-spacing-xl lg:px-spacing-0 z-40">
          <Select
            isDisabled={selectedDates.length === 0}
            colorScheme="gray"
            noOptionsMessage={() => 'Brak wspólnych posiłków na wybrane dni'}
            placeholder="Wybierz posiłek"
            value={purchasableItem.find((item) => item.value === selectedStake)}
            onChange={(value) => {
              if (value) {
                setSelectedStake(value.value);
              }
            }}
            options={purchasableItem}
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: 'white'
              })
            }}
          />
        </div>
        <div className="OrderOnPeriodOfTime__calendarContainer flex flex-col z-10">
          <div>
            <Calendar
              selectable={true}
              date={viewDate}
              longPressThreshold={3}
              views={views}
              defaultView={Views.MONTH}
              localizer={localizer}
              onSelectSlot={(slotInfo: any) => {
                if (isParentOrderLoading) {
                  return;
                }
                handleSelectSlot(
                  slotInfo,
                  minDate,
                  maxDate,
                  parentOrderContext,
                  selectedConsumerId,
                  setSelectedDates
                );
              }}
              onNavigate={handleNavigate}
              min={minDate.toDate()}
              max={maxDate.toDate()}
              style={{ height: isMobile ? 1200 : 600 }}
              components={{
                month: {
                  dateHeader: (props: any) => (
                    <CustomDayCell
                      disabled={isParentOrderLoading}
                      date={props.date}
                      events={events.filter((event) =>
                        moment(event.start).isSame(props.date, 'day')
                      )}
                    />
                  )
                },
                toolbar: () => (
                  <CustomToolbar
                    addToBasked={() => {
                      addOrderToBasket(
                        commonMeal,
                        selectedStake,
                        parentOrderContext,
                        selectedConsumerId,
                        selectedDates,
                        addItemToBasket,
                        tenantId?.toString()
                      );
                      setSelectedDates([]);
                      setSelectedStake(undefined);
                    }}
                    label={moment(viewDate).format('MMMM YYYY')}
                    onNavigate={customHandleNavigate}
                    clearSelectedDates={clearSelectedDates}
                    onToggleSelectAll={(isSelected: boolean) => {
                      toggleSelectAllDaysInMonth(
                        isSelected,
                        selectedConsumerId,
                        viewDate,
                        setSelectAllDaysInMonth,
                        parentOrderContext,
                        setSelectedDates
                      );
                    }}
                    isSelectAllChecked={selectAllDaysInMonth}
                    disabled={isParentOrderLoading}
                  />
                )
              }}
              eventPropGetter={(event: any) => eventPropGetter(event, fixedEvents)}
              dayPropGetter={(date: Date) =>
                dayPropGetter(date, selectedDates, parentOrderContext, selectedConsumerId)
              }
            />
          </div>
          <div className="w-full h-[100px]"></div>
          <div className="w-full px-spacing-xl py-spacing-3xl flex">
            <CustomChakraButton
              hierarchy="primary"
              iconPosition="onlyText"
              icon="xClose"
              size="sm"
              buttonProps={{
                className: 'flex grow lg:hidden',
                type: 'button',
                disabled: isParentOrderLoading,
                onClick: () => {
                  addOrderToBasket(
                    commonMeal,
                    selectedStake,
                    parentOrderContext,
                    selectedConsumerId,
                    selectedDates,
                    addItemToBasket,
                    tenantId?.toString()
                  );
                }
              }}>
              Dodaj do koszyka
            </CustomChakraButton>
          </div>
        </div>
      </div>
      <div className="hidden md:flex md:w-[750px]"></div>
    </section>
  );
};

export default OrderOnPeriodOfTime;
