import { TableContainer, Thead, Th, Box, Text, Checkbox, Tr, Flex } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { AxiosResponse } from 'axios';
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { useApi } from '../../../../../context/ApiProvider';
import { PaginationInterface } from '../../../../../shared/type/paginationInterface.type';
import { ParentInterface, PaginatedParentInterface } from '../../../../../shared/type/parent.type';
import CustomChakraButton from '../../../../CustomChakraButton/CustomChakraButton';
import { DataTable } from '../../../../DataTable/DataTable';
import Pagination from '../../../../Pagination/Pagination';
import AddParent from './AddParent/AddParent';
import ImportParentsFromFilePopUp from './ImportParentsFromFilePopUp/ImportParentsFromFilePopUp';

const ParentsTable = () => {
  const location = useLocation();
  const columnHelper = createColumnHelper<ParentInterface>();
  const { apiTenantParentController } = useApi();
  const { id: tenantId } = useParams();
  const [parents, setParents] = useState<ParentInterface[]>([]);
  const [selectedParents, setSelectedParents] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPopUp, setShowPopUp] = useState<{
    showImportParentsFromFilePopUp: boolean;
    showAddParentPopUp: boolean;
  }>({
    showImportParentsFromFilePopUp: false,
    showAddParentPopUp: false
  });
  const [actualPage, setActualPage] = useState<PaginationInterface>({
    totalCount: 0,
    hasNextPage: false,
    next: {
      limit: 0,
      offset: 0
    }
  });
  const [page, setPage] = useState({
    pageNumber: 1,
    pageSize: 30,
    maxPageNumber: 0
  });

  const handleShowPopUpValue = (name: string, value: boolean) => {
    setShowPopUp((prev) => ({ ...prev, [name]: value }));
  };

  const fetchParents = async () => {
    setIsLoading(true);
    try {
      const response: AxiosResponse<PaginatedParentInterface> = await apiTenantParentController(
        'paged-parent-list'
      ).post('', {
        pageNumber: page.pageNumber,
        pageSize: page.pageSize,
        tenantId
      });
      const { data, next, hasNextPage, totalCount } = response.data;
      setParents(data);
      setActualPage({ next, hasNextPage, totalCount });
      setPage((prev) => ({
        ...prev,
        maxPageNumber: Math.ceil(totalCount / page.pageSize)
      }));
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const deleteParents = useCallback((parentsToDelete: number[]) => {
    Promise.all(
      parentsToDelete.map(async (parentId) => {
        try {
          const response = await apiTenantParentController('').delete(`/${tenantId}/${parentId}`);
          if (response.status === 204) {
            return parentId;
          }
        } catch (error) {
          console.error(`Błąd usuwania rodzica ${parentId}:`, error);
        }
      })
    ).then((deletedParentIds) => {
      const successfullyDeletedIds = deletedParentIds.filter((id) => id !== undefined);

      setSelectedParents((prev) => prev.filter((el) => !successfullyDeletedIds.includes(el)));
      fetchParents();
    });
  }, [tenantId]);

  const columns = [
    columnHelper.display({
      id: 'select',
      header: () => (
        <Checkbox
          onChange={() => {
            setSelectedParents((prev) => {
              if (prev.length === parents.length) {
                return [];
              } else {
                return parents.map((parent) => parent.id);
              }
            });
          }}
          isChecked={selectedParents.length === parents.length}
          colorScheme="customOrange"
        />
      ),
      cell: (info) => {
        const { id } = info.row.original;
        return (
          <Checkbox
            isChecked={selectedParents.includes(id)}
            onChange={(e) => {
              setSelectedParents((prev) => {
                if (prev.includes(id)) {
                  return prev.filter((el) => el !== id);
                } else {
                  return [...prev, id];
                }
              });
            }}
            colorScheme="customOrange"
          />
        );
      }
    }),
    columnHelper.accessor('name', {
      cell: (info) => {
        const { name } = info.row.original;
        const { id } = info.row.original;
        const newPath = location.pathname.replace(
          'parents-table',
          `single-parent/${id}/list-of-orders`
        );
        return (
          <p className="text-grayLight-900 font-medium text-sm underline underline-offset-4">
            <Link to={newPath}>{name}</Link>
          </p>
        );
      },
      header: 'Nazwisko imię'
    }),
    columnHelper.accessor('login', {
      cell: (info) => {
        const login = info.getValue();

        return <p className="text-grayLight-900 font-medium text-sm">{login}</p>;
      },
      header: 'Adres email / login'
    }),
    columnHelper.accessor('phone', {
      cell: (info) => {
        const phone = info.getValue();

        return <p className="text-grayLight-900 font-normal text-sm">{phone ? phone : ''}</p>;
      },
      header: 'Telefon'
    }),
    columnHelper.accessor('children', {
      cell: (info) => {
        const children = info.getValue();

        const newPath = (kidId: number) =>
          location.pathname.replace('parents/parents-table', `children/single-kid/${kidId}/orders`);

        return (
          <ul>
            {children.map((kid) => (
              <li className="text-grayLight-900 font-medium text-sm underline underline-offset-4 pt-1 pb-1">
                <Link to={newPath(kid.id)}>
                  {kid.lastName} {kid.firstName}
                </Link>
              </li>
            ))}
          </ul>
        );
      },
      header: 'Przypisane dzieci'
    }),
    columnHelper.accessor('bankAccountPresent', {
      cell: (info) => {
        const bankAccountNumber = info.getValue();

        return (
          <p className="text-grayLight-900 font-normal text-sm">
            {bankAccountNumber ? 'tak' : 'nie'}
          </p>
        );
      },
      header: 'Numer konta'
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Edycja',
      cell: (info) => {
        const { id } = info.row.original;

        const newPath = location.pathname.replace(
          'parents-table',
          `single-parent/${id}/list-of-orders`
        );

        return (
          <div className="flex gap-spacing-sm">
            <Link to={newPath}>
              <button className="font-semibold text-sm text-grayWarm-950 bg-transparent border-none">
                Otwórz
              </button>
            </Link>
          </div>
        );
      }
    })
  ];

  useEffect(() => {
    fetchParents();
  }, [page.pageNumber, tenantId]);

  const handlePageChange = (pageNum: number) => {
    setPage((prev) => ({
      ...prev,
      pageNumber: pageNum
    }));
  };

  return (
    <>
      <section className="ParentsTable p-spacing-xl w-full pb-28">
        <Flex direction="column" className="Branches__table bg-white rounded-lg">
          <TableContainer h="100%" flex="1" minH="500px">
            <DataTable
              disableFilters={true}
              filterComponent={
                <CustomChakraButton
                  buttonProps={{
                    onClick: () => {
                      console.log(selectedParents);
                      return deleteParents(selectedParents);
                    }
                  }}
                  size="sm"
                  hierarchy="tertiaryColor"
                  iconPosition="onlyText">
                  Usuń zaznaczone
                </CustomChakraButton>
              }
              extraThead={
                <Thead>
                  <Tr>
                    <Th colSpan={columns.length + 1}>
                      <div className="w-full flex items-center justify-between">
                        <div className="flex flex-col basis-4/5">
                          <Box>
                            <Text
                              whiteSpace="normal"
                              overflow="hidden"
                              textOverflow="ellipsis"
                              className="font-semibold text-base normal-case text-grayLight-900">
                              Rodzice
                            </Text>
                          </Box>
                          <Box>
                            <Text
                              whiteSpace="normal"
                              overflow="hidden"
                              textOverflow="ellipsis"
                              className="font-normal text-sm normal-case text-grayLight-700">
                              Lista rodziców
                            </Text>
                          </Box>
                        </div>
                        <div className="flex gap-spacing-lg">
                          <CustomChakraButton
                            size="md"
                            hierarchy="linkGray"
                            iconPosition="left"
                            icon="import"
                            buttonProps={{
                              onClick: () => {
                                handleShowPopUpValue('showImportParentsFromFilePopUp', true);
                              }
                            }}>
                            Importuj listę
                          </CustomChakraButton>
                          <CustomChakraButton
                            size="md"
                            hierarchy="primary"
                            iconPosition="left"
                            icon="plus"
                            buttonProps={{
                              onClick: () => {
                                handleShowPopUpValue('showAddParentPopUp', true);
                              }
                            }}>
                            Dodaj rodzica
                          </CustomChakraButton>
                        </div>
                      </div>
                    </Th>
                  </Tr>
                </Thead>
              }
              columns={columns}
              isLoading={isLoading}
              data={parents}
            />
          </TableContainer>
          <Pagination
            pageNumber={page.pageNumber}
            maxPageNumber={page.maxPageNumber}
            hasNextPage={actualPage.hasNextPage}
            onPageChange={handlePageChange}
          />
        </Flex>
      </section>
      {showPopUp.showImportParentsFromFilePopUp ? (
        <ImportParentsFromFilePopUp
          isOpen={showPopUp.showImportParentsFromFilePopUp}
          onClose={() => {
            handleShowPopUpValue('showImportParentsFromFilePopUp', false);
            fetchParents();
          }}
        />
      ) : null}
      {showPopUp.showAddParentPopUp ? (
        <AddParent
          isOpen={showPopUp.showAddParentPopUp}
          onClose={() => {
            handleShowPopUpValue('showAddParentPopUp', false);
            fetchParents();
          }}
        />
      ) : null}
    </>
  );
};

export default ParentsTable;
