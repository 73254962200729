import { ModalBody } from '@chakra-ui/react';
import { AxiosResponse, HttpStatusCode } from 'axios';
import { Select } from 'chakra-react-select';
import React, { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../../../../../../../context/ApiProvider';
import { useTenantAdminBasketContext } from '../../../../../../../../../context/TenantAdminBasketProvider';
import { CalendarEventsInterface } from '../../../../../../../../../shared/type/calendarEvents.type';
import { OrderedItemInterface } from '../../../../../../../../../shared/type/orderForParent.type';
import { ParentWithKidInterface } from '../../../../../../../../../shared/type/parent.type';
import {
  ParentOrderContextInterface,
  PurchasableItemInterface
} from '../../../../../../../../../shared/type/parentOrderContext.type';
import moment from 'moment';
// @ts-ignore
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import {
  getAvailableMeals,
  handleSelectSlot,
  toggleSelectAllDaysInMonth,
  eventPropGetter,
  dayPropGetter,
  addOrderToBasket,
  groupedOrders
} from '../../../../../../../../../utils/calendarOrderHelper';
import generateFixedEvents from '../../../../../../../../../utils/calendarOrderHelper/getFixedEvents';
import handleNavigate from '../../../../../../../../../utils/calendarOrderHelper/handleNavigate';
import EmptyBasket from '../../../../../../../../Basket/EmptyBasket/EmptyBasket';
import CustomDayCell from '../../../../../../../../ChildOwnerComponents/ParentOrder/OrderOnPeriodOfTime/CustomDayCell/CustomDayCell';
import CustomToolbar from '../../../../../../../../ChildOwnerComponents/ParentOrder/OrderOnPeriodOfTime/CustomToolbar/CustomToolbar';
import CustomHorizontalTabs from '../../../../../../../../CustomHorizontalTabs/CustomHorizontalTabs';
import IconComponent from '../../../../../../../../IconComponent/IconComponent';
import calculatePrice from '../../../../../../../../../utils/calculators/calculatePrice';
import { useThemeContext } from '../../../../../../../../../context/ThemeProvider';

const CompleteCashOrder = () => {
  const {
    isEmpty,
    addItemToBasket,
    setUserData,
    parentBasketContext,
    changeProductQuantity,
    resetBasket,
    calculatedPayment,
    deleteProductFromBasket
  } = useTenantAdminBasketContext();

  const { apiTenantParentController, apiOrderController } = useApi();
  const { id: tenantId, parentId } = useParams<{ id: string; parentId: string }>();
  const [parentWithKids, setParentWithKids] = useState<ParentWithKidInterface>();
  const [consumerId, setConsumerId] = useState<number | undefined>(undefined);
  const [parentOrderContext, setParentOrderContext] = useState<ParentOrderContextInterface>();
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [fixedEvents, setFixedEvents] = useState<CalendarEventsInterface[]>([]);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [selectAllDaysInMonth, setSelectAllDaysInMonth] = useState(false);
  const [selectedStake, setSelectedStake] = useState<number>();
  const [commonMeal, setCommonMeals] = useState<PurchasableItemInterface[]>([]);
  const [collapsedConsumers, setCollapsedConsumers] = useState<{ [consumerId: number]: boolean }>(
    {}
  );
  const { isMobile } = useThemeContext();

  // calendar language
  moment.locale('pl');
  const localizer = momentLocalizer(moment);
  // calendar view mode
  const views = useMemo(() => [Views.MONTH], []);
  // min and max calendar date
  const minDate = null;
  const maxDate = moment(parentOrderContext?.maxOrderDate);

  const fetchKids = async () => {
    try {
      const response: AxiosResponse<ParentWithKidInterface> = await apiTenantParentController(
        'details'
      ).get(`/${tenantId}/${parentId}`);
      setParentWithKids(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchParentContext = async () => {
    try {
      const response: AxiosResponse<ParentOrderContextInterface> = await apiOrderController(
        'parent-order-context'
      ).post('', {
        tenantId: Number(tenantId),
        userId: Number(parentId),
        year: calendarDate.getFullYear(),
        month: calendarDate.getMonth() + 1
      });
      setUserData(
        response.data.consumerOrderContexts.map((consumer) => ({
          consumerId: consumer.consumerId,
          saldo: consumer.saldo,
          creditLimit: consumer.creditLimit,
          provision: consumer.provision
        }))
      );
      setParentOrderContext(response.data);
    } catch (error: any) {}
  };

  const toggleCollapse = (consumerId: number) => {
    setCollapsedConsumers((prevState) => ({
      ...prevState,
      [consumerId]: !prevState[consumerId]
    }));
  };

  const clearBasket = async () => {
    try {
      const response = await apiOrderController('clear-basket').delete('');
      if (response.status === HttpStatusCode.Ok) resetBasket();
    } catch (e: any) {}
  };

  const consumerMap = parentWithKids?.consumers;

  const handleDelete = (consumerId: number, item: OrderedItemInterface) => {
    deleteProductFromBasket(consumerId, item);
  };

  const handleQuantityChange = (
    consumerId: number,
    item: OrderedItemInterface,
    increment: boolean
  ) => {
    changeProductQuantity(consumerId, item, increment);
  };

  const basketItems = useMemo(() => {
    if (!parentBasketContext?.ordersForChildren) {
      return [];
    }

    return parentBasketContext.ordersForChildren
      .filter((orderForChild) => orderForChild.consumerId === consumerId)
      .flatMap((orderForChild) =>
        orderForChild.orderedItems.map((orderedItem) => ({
          title: (
            <>
              <p className="font-medium text-xs text-grayBlue-700">
                {orderedItem.purchasableItem.name} ({orderedItem.count} szt.)
              </p>
            </>
          ),
          start: moment(orderedItem.when, 'YYYY-MM-DD').startOf('day').toDate(),
          end: moment(orderedItem.when, 'YYYY-MM-DD').endOf('day').toDate(),
          isInBasket: true
        }))
      );
  }, [parentBasketContext, consumerId]);

  const holidays = useMemo(() => {
    const consumerOrderContext = parentOrderContext?.consumerOrderContexts.find(
      (consumer) => consumer.consumerId === consumerId
    );
    if (!consumerOrderContext) {
      return [];
    }

    return consumerOrderContext.orderDays
      .filter((day) => !day.workingDay && !day.salesBlocked && !!day.comment.length)
      .map((day) => {
        return {
          title: (
            <>
              <p
                className={`font-medium ${isMobile && 'bg-orange-50 rounded box-border border font-medium border-orange-200 text-orange-700 p-spacing-xxs '} text-xs text-grayBlue-700`}>
                {day.comment}
              </p>
            </>
          ),
          start: moment(day.when, 'YYYY-MM-DD').startOf('day').toDate(),
          end: moment(day.when, 'YYYY-MM-DD').endOf('day').toDate(),
          isFixed: true,
          consumerId: consumerId,
          workingDay: day.workingDay
        };
      });
  }, [parentOrderContext, consumerId]);

  const events = useMemo(() => {
    const combinedEvents = [
      ...fixedEvents,
      ...holidays,
      ...basketItems,
      ...selectedDates.map((date) => ({
        start: date,
        end: date,
        isInBasket: false,
        isFixed: false
      }))
    ];

    return combinedEvents;
  }, [fixedEvents, selectedDates, basketItems]);

  useEffect(() => {
    generateFixedEvents(parentOrderContext, Number(consumerId), setFixedEvents);
  }, [consumerId, parentOrderContext]);

  useEffect(() => {
    if (parentWithKids?.consumers && parentWithKids.consumers.length > 0) {
      setConsumerId(parentWithKids.consumers[0].id);
    }
  }, [parentWithKids]);

  useEffect(() => {
    fetchParentContext();
  }, [calendarDate]);

  useEffect(() => {
    setCommonMeals(getAvailableMeals(parentOrderContext, consumerId, selectedDates));
  }, [selectedDates]);

  useEffect(() => {
    fetchKids();
    fetchParentContext();
  }, []);

  return (
    <ModalBody
      className="flex gap-spacing-xl"
      sx={{
        overflowY: 'auto'
      }}>
      <div className="grow flex flex-col gap-spacing-xl">
        <div className="flex flex-col gap-spacing-4xl">
          <div className="flex flex-col text-grayLight-900 font-semibold text-lg gap-spacing-lg">
            <h2 className="font-semibold text-2xl">Wybierz posiłek i daty</h2>
            <p style={{ fontSize: '16px' }} className="font-normal text-grayLight-600">
              Wybierz rodzaj posiłku oraz dni w jakie dziecko ma otrzymać posiłek, następnie dodaj
              je do koszyka.
            </p>
          </div>
          <div>
            <CustomHorizontalTabs
              data={parentWithKids?.consumers.map((consumer) => ({
                value: consumer.id,
                label: `${consumer.firstName} ${consumer.lastName}`
              }))}
              onChange={(value) => {
                setConsumerId(value);
              }}
            />
          </div>
        </div>
        <div className="OrderOnPeriodOfTime__selectServing z-40">
          <Select
            isDisabled={selectedDates.length === 0}
            colorScheme="gray"
            noOptionsMessage={() => 'Brak wspólnych posiłków na wybrane dni'}
            placeholder="Wybierz posiłek"
            onChange={(value) => {
              if (value) {
                setSelectedStake(value.value);
              }
            }}
            options={commonMeal.map((meal) => ({
              label: meal.name,
              value: meal.stakeId
            }))}
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: 'white'
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 1000
              })
            }}
          />
        </div>
        <div className="OrderOnPeriodOfTime__calendarContainer overflow-y-scroll z-10">
          <Calendar
            selectable={true}
            date={calendarDate}
            views={views}
            defaultView={Views.MONTH}
            localizer={localizer}
            onSelectSlot={(slotInfo: any) => {
              handleSelectSlot(
                slotInfo,
                minDate,
                maxDate,
                parentOrderContext,
                consumerId,
                setSelectedDates
              );
            }}
            onNavigate={handleNavigate}
            min={minDate}
            max={maxDate.toDate()}
            style={{ height: 600 }}
            components={{
              month: {
                dateHeader: (props: any) => (
                  <CustomDayCell
                    date={props.date}
                    events={events.filter((event) => moment(event.start).isSame(props.date, 'day'))}
                  />
                )
              },
              toolbar: () => (
                <CustomToolbar
                  addToBasked={() => {
                    addOrderToBasket(
                      commonMeal,
                      selectedStake,
                      parentOrderContext,
                      consumerId,
                      selectedDates,
                      addItemToBasket,
                      tenantId
                    );
                  }}
                  label={moment(calendarDate).format('MMMM YYYY')}
                  onNavigate={(action) => {
                    handleNavigate(
                      action,
                      setSelectedDates,
                      calendarDate,
                      setCalendarDate,
                      minDate,
                      maxDate
                    );
                  }}
                  clearSelectedDates={() => {
                    setSelectedDates([]);
                  }}
                  onToggleSelectAll={(isSelected: boolean) => {
                    toggleSelectAllDaysInMonth(
                      isSelected,
                      consumerId,
                      calendarDate,
                      setSelectAllDaysInMonth,
                      parentOrderContext,
                      setSelectedDates
                    );
                  }}
                  isSelectAllChecked={selectAllDaysInMonth}
                />
              )
            }}
            eventPropGetter={(event: any) => eventPropGetter(event, fixedEvents)}
            dayPropGetter={(date: Date) =>
              dayPropGetter(date, selectedDates, parentOrderContext, consumerId)
            }
          />
        </div>
      </div>
      <div
        style={{ width: '500px' }}
        className="overflow-y-scroll no-scrollbar bg-white box-border border-l shadow-custom-heavy rounded-2xl">
        <div className="Basket flex flex-col gap-spacing-xl w-full h-full pt-spacing-xl pr-spacing-xl pb-spacing-4xl pl-spacing-xl">
          <div className="Basket__contextContainer flex flex-col grow gap-spacing-lg bg-white">
            <div className="Basket__header flex justify-between">
              <p className="font-semibold text-grayLight-900" style={{ fontSize: '18px' }}>
                Koszyk
              </p>
              {!isEmpty && (
                <button
                  className="flex gap-spacing-sm items-center"
                  type="button"
                  onClick={clearBasket}>
                  <IconComponent iconName="xClose" color="#B42318" />
                  <p className="text-error-700 font-semibold text-sm">Wyczyść koszyk</p>
                </button>
              )}
            </div>

            <div
              className={`Basket__context grow flex flex-col ${isEmpty ? 'justify-center items-center' : 'justify-start'}`}>
              {isEmpty && <EmptyBasket />}

              {!isEmpty &&
                groupedOrders(parentBasketContext) &&
                Object.keys(groupedOrders(parentBasketContext)).map((consumerId) => {
                  const consumer = consumerMap?.find(
                    (consumer) => consumer.id === Number(consumerId)
                  );
                  const isCollapsed = collapsedConsumers[parseInt(consumerId)];

                  const paymentData = calculatedPayment?.consumerCalculations?.find(
                    (item) => item.consumerId === parseInt(consumerId)
                  );

                  return (
                    <div
                      key={consumerId}
                      style={{ position: 'relative' }}
                      className="flex flex-col gap-spacing-xl pt-spacing-4xl pb-spacing-xl">
                      <div className="flex items-center justify-between gap-spacing-md">
                        <div
                          className="flex items-center text-grayLight-700 font-medium gap-spacing-md"
                          style={{ fontSize: '16px' }}>
                          <p>{consumer && `${consumer.firstName} ${consumer.lastName}`}</p>
                        </div>

                        <div className="flex items-center gap-spacing-md">
                          <p
                            className="font-semibold text-grayLight-900"
                            style={{ fontSize: '16px' }}>
                            suma: {calculatePrice(paymentData?.calculationDto.basicToPay, 'zł')}
                          </p>
                          <button
                            type="button"
                            onClick={() => toggleCollapse(parseInt(consumerId))}>
                            <div className="rotate-90">
                              <IconComponent
                                iconName={isCollapsed ? 'arrowRight' : 'arrowRight'}
                                color="#667085"
                              />
                            </div>
                          </button>
                        </div>
                      </div>
                      {!isCollapsed && (
                        <div className="flex flex-col gap-spacing-xl">
                          {Object.entries(
                            groupedOrders(parentBasketContext)[parseInt(consumerId)].reduce(
                              (acc, item) => {
                                const dateKey = new Date(item.when).toLocaleDateString();
                                if (!acc[dateKey]) acc[dateKey] = [];
                                acc[dateKey].push(item);
                                return acc;
                              },
                              {} as { [key: string]: OrderedItemInterface[] }
                            )
                          )
                            .sort(([dateA], [dateB]) => {
                              const dateObjA = new Date(dateA.split('.').reverse().join('-'));
                              const dateObjB = new Date(dateB.split('.').reverse().join('-'));
                              return dateObjA.getTime() - dateObjB.getTime();
                            })
                            .map(([date, items], index) => (
                              <div key={index} className="flex flex-col gap-spacing-md">
                                <p className="text-grayLight-700 text-sm font-normal">
                                  {(() => {
                                    const dateObj = new Date(date.split('.').reverse().join('-'));
                                    const weekday = dateObj.toLocaleDateString('pl-PL', {
                                      weekday: 'long'
                                    });
                                    return `${weekday.charAt(0).toUpperCase()}${weekday.slice(1)} (${date})`;
                                  })()}
                                </p>
                                {items.map((item: OrderedItemInterface, itemIndex: number) => (
                                  <div key={itemIndex} className="flex flex-col gap-spacing-sm">
                                    <div className="flex justify-between border-b box-border border-grayLight-200">
                                      <p
                                        className="text-grayLight-900 font-medium"
                                        style={{ fontSize: '16px' }}>
                                        {item.purchasableItem.name}
                                      </p>
                                      <div className="flex gap-spacing-md">
                                        {item.purchasableItem.originalPrice !==
                                          item.purchasableItem.price && (
                                          <p
                                            className="text-grayLight-500 font-medium line-through"
                                            style={{ fontSize: '16px' }}>
                                            {calculatePrice(
                                              item.purchasableItem.originalPrice,
                                              null
                                            )}
                                          </p>
                                        )}
                                        <p
                                          className="text-grayLight-900 font-semibold"
                                          style={{ fontSize: '16px' }}>
                                          {calculatePrice(item.purchasableItem.price, null)}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="flex justify-between pt-spacing-md pb-spacing-md">
                                      <button
                                        onClick={() => handleDelete(parseInt(consumerId), item)}
                                        type="button"
                                        className="flex gap-spacing-sm items-center">
                                        <IconComponent iconName="xClose" color="#475467" />
                                        <p className="text-sm font-semibold text-grayLight-600">
                                          Usuń
                                        </p>
                                      </button>
                                      <div className="flex items-center pt-2.5 pr-3.5 pb-2.5 pl-3.5 border box-border border-grayLight-300 rounded-md gap-spacing-md">
                                        <button
                                          disabled={item.count === 1}
                                          type="button"
                                          onClick={() =>
                                            handleQuantityChange(parseInt(consumerId), item, false)
                                          }>
                                          <IconComponent iconName="minus" color="#667085" />
                                        </button>
                                        <div>
                                          <p
                                            className="text-grayLight-500 font-normal"
                                            style={{ fontSize: '16px' }}>
                                            {item.count}
                                          </p>
                                        </div>
                                        <button
                                          type="button"
                                          onClick={() =>
                                            handleQuantityChange(parseInt(consumerId), item, true)
                                          }>
                                          <IconComponent iconName="plus" color="#667085" />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </ModalBody>
  );
};

export default CompleteCashOrder;
