import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { useApi } from '../../../../context/ApiProvider';
import { MonthDateType } from '../../../ChooseMonths/ChooseMonths';
import { KitchenSummary, KitchenSummaryItem } from '../../../../shared/type/kitchenSummary.type';
import useAuth from '../../../../hooks/useAuth';

type useSummaryReturn = {
  currentTenantId: number;
  currentDate: MonthDateType;
  setCurrentDate: React.Dispatch<React.SetStateAction<MonthDateType>>;
  mainSummaryData: KitchenSummaryItem | undefined;
  isLoading: boolean;
  tenantsOption: { label: string; value: number }[];
  selectedTenants: number[];
  setSelectedTenants: (value: number[]) => void;
  tenantsSummariesData: KitchenSummaryItem[];
  onDownloadReport: (documentFormat: 'PDF' | 'XLSX') => Promise<void>;
};

export const useSummary = (): useSummaryReturn => {
  const today = new Date();
  const { id: tenantId } = useParams();
  const { auth } = useAuth();
  const tenantsOption = auth.availableTenants.map((tenant) => ({
    label: tenant.name,
    value: tenant.id
  }));
  const [selectedTenants, setSelectedTenants] = useState<number[]>([Number(tenantId)]);
  const [currentDate, setCurrentDate] = useState<MonthDateType>({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate()
  });
  const { apiTenantKitchenController, apiReportController } = useApi();
  const [mainSummaryData, setMainSummaryData] = useState<KitchenSummaryItem | undefined>(undefined);
  const [tenantsSummariesData, setTenantsSummariesData] = useState<KitchenSummaryItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchSummary = useCallback(async () => {
    setIsLoading(true);
    try {
      const response: AxiosResponse<KitchenSummary> = await apiTenantKitchenController(
        'summary'
      ).post(``, {
        targetDate: `${currentDate.year}-${currentDate.month < 10 ? '0' + currentDate.month : currentDate.month}-${currentDate.day < 10 ? '0' + currentDate.day : currentDate.day}`,
        tenantIds: selectedTenants
      });
      setMainSummaryData(response.data.mainSummary);
      setTenantsSummariesData(response.data.tenantSummaries);
    } catch (error) {
      console.warn(`${error}`);
    } finally {
      setIsLoading(false);
    }
  }, [
    tenantId,
    selectedTenants,
    currentDate,
    setIsLoading,
    setMainSummaryData,
    setTenantsSummariesData
  ]);

  const onDownloadReport = async (outputType: 'PDF' | 'XLSX') => {
    const tenantId = selectedTenants.join(',');
    try {
      const response = await apiReportController('kitchen-summary').get('', {
        params: {
          tenantId,
          targetDate: `${currentDate.day < 10 ? '0' + currentDate.day : currentDate.day}-${currentDate.month < 10 ? '0' + currentDate.month : currentDate.month}-${currentDate.year}`,
          outputType
        },
        responseType: 'blob'
      });

      const fileName = response.headers['content-disposition']?.split('filename=')[1];

      const blob = new Blob([response.data], {
        type: outputType === 'PDF' ? 'application/pdf' : 'application/vnd.ms-excel'
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${fileName}`;
      document.body.appendChild(link);
      link.click();

      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to download report:', error);
    }
  };

  useEffect(() => {
    if (tenantId === '0') return;
    fetchSummary();
  }, [tenantId, currentDate, selectedTenants]);

  return {
    currentTenantId: Number(tenantId),
    currentDate,
    setCurrentDate,
    mainSummaryData,
    isLoading,
    tenantsOption,
    selectedTenants,
    setSelectedTenants,
    tenantsSummariesData,
    onDownloadReport
  };
};
