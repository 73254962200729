import { Radio, RadioGroup } from '@chakra-ui/react';
import React from 'react';
import { useSettingFormContext } from '../../../../../../context/SettingsFormContext';
import CustomInput from '../../../../../CustomInput/CustomInput';
import CheckboxInput from '../../../../../Forms/CheckboxInput/CheckboxInput';
import CustomInputRadio from '../../../../../Forms/CustomInputRadio/CustomInputRadio';
import IconComponent from '../../../../../IconComponent/IconComponent';
import StickyNotes from '../../../../../StickyNotes/StickyNotes';

const SalesDataSettings = () => {
  const { settingsForm, setIsEdited, updateForm } = useSettingFormContext();

  const createTimeValue = (hour?: number, minute?: number) => {
    let stringHour, stringMinute;
    if (`${hour}`.length < 2) {
      stringHour = '0' + hour;
    } else {
      stringHour = `${hour}`;
    }

    if (`${minute}`.length < 2) {
      stringMinute = '0' + minute;
    } else {
      stringMinute = `${minute}`;
    }

    return stringHour + ':' + stringMinute;
  };

  return (
    <div className="grow flex flex-col gap-spacing-lg">
      <div className="text-sm font-medium text-grayLight-500 border-b border-grayLight-200">
        Dane sprzedaży
      </div>
      <div className="flex flex-col gap-spacing-4xl">
        <div className="flex flex-col gap-spacing-xs">
          <div className="Setting__sectionHeader">
            <p>Link do auto-rejestracji</p>
          </div>
          <div className="relative" style={{ width: '450px' }}>
            <CustomInput
              destructive
              type="default"
              size="sm"
              inputParams={{
                value: settingsForm?.parentAutoRegistrationUrl,
                name: 'parentAutoRegistrationUrl',
                disabled: true,
                onChange: (e) => {
                  updateForm(e.target.name, e.target.value);
                },
                placeholder: settingsForm?.parentAutoRegistrationUrl || ''
              }}
            />
            <button
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 text-sm text-gray-800 px-2 py-1 rounded"
              onClick={() => {
                navigator.clipboard.writeText(settingsForm?.parentAutoRegistrationUrl || '');
              }}>
              <IconComponent iconName="copy" />
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-spacing-xs">
          <p className="font-normal text-base text-grayLight-600">
            Określ limit salda kredytowego w oddziale do jakiego dzieci mogą brać posiłki na kredyt:
          </p>
          <div className="w-44">
            <CustomInput
              destructive
              type="default"
              size="sm"
              inputParams={{
                value: settingsForm?.creditLimit,
                name: 'creditLimit',
                onChange: (e) => {
                  updateForm(e.target.name, e.target.value);
                },
                placeholder: 'Podaj kwotę limitu',
                type: 'number',
                step: '.01',
                min: 0
              }}
            />
          </div>
          <p className="font-normal text-base text-grayLight-600">
            0 oznacza wyłączoną opcję kredytu.
          </p>
        </div>
        <div className="w-full flex flex-col gap-spacing-lg">
          <div className="Setting__sectionHeader">
            <p>Posiłki na kredyt</p>
            <p>Określ kto może brać posiłki na kredyt</p>
          </div>
          <div className="flex flex-col gap-spacing-xl">
            <div className="flex flex-col gap-spacing-lg">
              <CheckboxInput
                text="Rodzic"
                textSize="lg"
                checkboxParams={{
                  name: 'creditTakingByParents',
                  isChecked: settingsForm?.creditTakingByParents,
                  onChange: (e) => {
                    updateForm(e.target.name, !settingsForm?.creditTakingByParents);
                  }
                }}
              />
              <CheckboxInput
                text="Wydawka(dziecko)"
                textSize="lg"
                checkboxParams={{
                  name: 'creditTakingByMealDistribution',
                  isChecked: settingsForm?.creditTakingByMealDistribution,
                  onChange: (e) => {
                    updateForm(e.target.name, !settingsForm?.creditTakingByMealDistribution);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <RadioGroup
          value={`${settingsForm?.orderToDayMode}`}
          name="orderToDayMode"
          onChange={(value: string) => {
            setIsEdited(true);
            updateForm('orderToDayMode', value === 'true');
          }}>
          <div className="w-full flex flex-col gap-spacing-lg">
            <div className="Setting__sectionHeader">
              <p>Terminy składania zamówień</p>
              <p>Określ do kiedy można składać zamówienia</p>
            </div>
            <div className="flex flex-col gap-spacing-xl">
              <div className="flex">
                <div className="flex-1">
                  <CustomInputRadio
                    header="Zamówienie do dnia miesiąca"
                    message={
                      <CustomInput
                        destructive
                        type="default"
                        size="sm"
                        label="Dzień"
                        inputParams={{
                          value: settingsForm?.orderToDay || undefined,
                          disabled: !settingsForm?.orderToDayMode,
                          required: settingsForm?.orderToDayMode,
                          name: 'orderToDay',
                          placeholder: settingsForm?.orderToDayMode ? '1' : undefined,
                          onChange: (e) => {
                            updateForm(e.target.name, e.target.value);
                          },
                          type: 'number',
                          min: 1,
                          max: 31
                        }}
                      />
                    }>
                    <Radio
                      value="true"
                      isChecked={settingsForm?.orderToDayMode === true}
                      name="orderToDayMode"
                      colorScheme="customOrange"
                    />
                  </CustomInputRadio>
                </div>
                <div className="flex-1">
                  <StickyNotes message="Np. Pierwszy dzień miesiąca do końca dnia (00:00)." />
                </div>
              </div>
              <div className="flex">
                <div className="flex-1">
                  <CustomInputRadio
                    header="Zamówienie na X godzin przed:"
                    message={
                      <div className="flex items-end gap-spacing-3xl">
                        <CustomInput
                          destructive
                          type="default"
                          size="sm"
                          label="Godziny wyprzedania"
                          inputParams={{
                            name: 'endOfSalesHourLimit',
                            value: settingsForm?.endOfSalesHourLimit,
                            disabled: settingsForm?.orderToDayMode,
                            required: !settingsForm?.orderToDayMode,
                            placeholder: !settingsForm?.orderToDayMode ? '1' : undefined,
                            type: 'number',
                            min: 1
                          }}
                        />
                        <CustomInput
                          destructive
                          type="default"
                          size="sm"
                          label="Godziny zakończenia sprzedaży danego dnia"
                          inputParams={{
                            disabled: settingsForm?.orderToDayMode,
                            value: createTimeValue(
                              settingsForm?.salesReferenceHour,
                              settingsForm?.salesReferenceMinute
                            ),
                            onChange: (e) => {
                              let [hour, minute] = e.target.value.split(':');
                              updateForm('salesReferenceMinute', Number(minute));
                              updateForm('salesReferenceHour', Number(hour));
                            },
                            required: !settingsForm?.orderToDayMode,
                            placeholder: !settingsForm?.orderToDayMode ? '12:34' : undefined,
                            type: 'time',
                            min: 1,
                            max: 31
                          }}
                        />
                      </div>
                    }>
                    <Radio
                      value="false"
                      isChecked={settingsForm?.orderToDayMode === false}
                      name="orderToDayMode"
                      colorScheme="customOrange"
                    />
                  </CustomInputRadio>
                </div>
                <div className="flex-1">
                  <StickyNotes message="Np. Przy wyborze 12 godzin i godzinie 10:00 składanie zamówień będzie możliwe do godziny 22:00 dnia poprzedzającego zamówienie." />
                </div>
              </div>
            </div>
          </div>
        </RadioGroup>
        <div className="w-full flex flex-col gap-spacing-lg">
          <div className="Setting__sectionHeader">
            <p>Maksymalna liczba zamówień na dzień</p>
            <p>Określ maksymalną ilość zamówień</p>
          </div>
          <div className="flex flex-row gap-spacing-3xl">
            <div className="flex">
              <div className="max-w-80">
                <CustomInput
                  destructive
                  type="default"
                  size="sm"
                  label=""
                  inputParams={{
                    value: settingsForm?.totalSalesLimit,
                    name: 'totalSalesLimit',
                    onChange: (e) => {
                      updateForm(e.target.value, e.target.name);
                    },
                    placeholder: '1',
                    type: 'number',
                    min: 1
                  }}
                />
              </div>
              <div className="flex-1"></div>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-spacing-lg">
          <div className="Setting__sectionHeader">
            <p>Zamówienia</p>
            <p>Określ minimalne zamówienie</p>
          </div>
          <div className="flex flex-col gap-spacing-3xl">
            <RadioGroup
              value={settingsForm?.orderSizeMode}
              name="orderSizeMode"
              onChange={(value: string) => {
                setIsEdited(true);
                updateForm('orderSizeMode', value);
              }}>
              <CustomInputRadio header="Dzień" message="Użytkownik dowolnie wybiera stawkę">
                <Radio value="DAILY" name="orderSizeMode" colorScheme="customOrange" />
              </CustomInputRadio>
              <CustomInputRadio
                header="Tydzień"
                message="Użytkownik wybiera stawkę na cały tydzień">
                <Radio value="WEEKLY" name="orderSizeMode" colorScheme="customOrange" />
              </CustomInputRadio>
              <CustomInputRadio
                header="Miesiąc"
                message="Użytkownik dowolnie wybiera stawkę na cały miesiąc">
                <Radio value="MONTHLY" name="orderSizeMode" colorScheme="customOrange" />
              </CustomInputRadio>
            </RadioGroup>
          </div>
        </div>
        <div className="w-full flex flex-col gap-spacing-lg">
          <div className="Setting__sectionHeader">
            <p>Dni pracy</p>
            <p>Określ w jakie dni pracuje oddział</p>
          </div>
          <div className="flex flex-row gap-spacing-3xl">
            <div className="flex flex-col gap-spacing-xl">
              <CheckboxInput
                text="Poniedziałek"
                textSize="lg"
                checkboxParams={{
                  isChecked: settingsForm?.monday,
                  name: 'monday',
                  onChange: (e) => {
                    updateForm(e.target.name, !settingsForm?.monday);
                  }
                }}
              />
              <CheckboxInput
                text="Wtorek"
                textSize="lg"
                checkboxParams={{
                  isChecked: settingsForm?.tuesday,
                  name: 'tuesday',
                  onChange: (e) => {
                    updateForm(e.target.name, !settingsForm?.tuesday);
                  }
                }}
              />
              <CheckboxInput
                text="Środa"
                textSize="lg"
                checkboxParams={{
                  isChecked: settingsForm?.wednesday,
                  name: 'wednesday',
                  onChange: (e) => {
                    updateForm(e.target.name, !settingsForm?.wednesday);
                  }
                }}
              />
              <CheckboxInput
                text="Czwartek"
                textSize="lg"
                checkboxParams={{
                  isChecked: settingsForm?.thursday,
                  name: 'thursday',
                  onChange: (e) => {
                    updateForm(e.target.name, !settingsForm?.thursday);
                  }
                }}
              />
              <CheckboxInput
                text="Piątek"
                textSize="lg"
                checkboxParams={{
                  isChecked: settingsForm?.friday,
                  name: 'friday',
                  onChange: (e) => {
                    updateForm(e.target.name, !settingsForm?.friday);
                  }
                }}
              />
            </div>
            <div className="flex flex-col gap-spacing-xl">
              <CheckboxInput
                text="Sobota"
                textSize="lg"
                checkboxParams={{
                  isChecked: settingsForm?.saturday,
                  name: 'saturday',
                  onChange: (e) => {
                    updateForm(e.target.name, !settingsForm?.saturday);
                  }
                }}
              />
              <CheckboxInput
                text="Niedziela"
                textSize="lg"
                checkboxParams={{
                  isChecked: settingsForm?.sunday,
                  name: 'sunday',
                  onChange: (e) => {
                    updateForm(e.target.name, !settingsForm?.sunday);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesDataSettings;
