import React, { useEffect, useState } from 'react';
import { useParentBasketContext } from '../../../../context/ParentBasketProvider';
import { useParentDashboardContextContext } from '../../../../context/ParentDashboardContext';
import { useParentOrderContextContext } from '../../../../context/ParentOrderContext';
import { useThemeContext } from '../../../../context/ThemeProvider';
import CustomBadge from '../../../CustomBadge/CustomBadge';
import CustomChakraButton from '../../../CustomChakraButton/CustomChakraButton';
import CustomHorizontalTabs from '../../../CustomHorizontalTabs/CustomHorizontalTabs';
import WideDateInput from '../../../Forms/WideDateInput/WideDateInput';
import calculatePrice from '../../../../utils/calculators/calculatePrice';

const OrderOnDays = () => {
  const todayDate = new Date();
  const { isMobile } = useThemeContext();
  const { setBasketStatus, parentDashboardContext } = useParentDashboardContextContext();
  const { addItemToBasket } = useParentBasketContext();
  const {
    parentOrderContext,
    setParentOrderContextDate,
    tenantId,
    setSkipFetchingParentOrderContext
  } = useParentOrderContextContext();
  const [selectedConsumerId, setSelectedUserId] = useState<number>();

  const [date, setDate] = useState<{
    year: number;
    month: number;
    day: number;
  }>({
    year: todayDate.getFullYear(),
    month: todayDate.getMonth() + 1,
    day: todayDate.getDate()
  });

  useEffect(() => {
    setParentOrderContextDate({
      year: date.year,
      month: date.month
    });
  }, [date.month]);

  useEffect(() => {
    if (!isMobile) {
      setBasketStatus({
        forceShow: true,
        show: true
      });
    }
  }, []);

  const formatDateForComparison = (year: number, month: number, day: number) => {
    const formattedMonth = String(month).padStart(2, '0');
    const formattedDay = String(day).padStart(2, '0');
    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  const selectedOrder = parentOrderContext?.consumerOrderContexts
    .find((context) => context.consumerId === selectedConsumerId)
    ?.orderDays?.find(
      (item) => item.when === formatDateForComparison(date.year, date.month, date.day)
    );

  useEffect(() => {
    const minDate = parentOrderContext?.minOrderDate;
    const maxDate = parentOrderContext?.maxOrderDate;

    if (minDate && maxDate) {
      const today = new Date();
      if (today < new Date(minDate)) {
        setDate({
          year: new Date(minDate).getFullYear(),
          month: new Date(minDate).getMonth() + 1,
          day: new Date(minDate).getDate()
        });
      } else if (today > new Date(maxDate)) {
        setDate({
          year: new Date(maxDate).getFullYear(),
          month: new Date(maxDate).getMonth() + 1,
          day: new Date(maxDate).getDate()
        });
      }
    }
  }, [parentOrderContext?.minOrderDate, parentOrderContext?.maxOrderDate]);

  useEffect(() => {
    setSkipFetchingParentOrderContext(false);

    return () => {
      setSkipFetchingParentOrderContext(true);
    };
  }, []);

  return (
    <section className="OrderOnDays w-full h-full flex bg-grayLight-50">
      <div className="basis-full md:w-full flex flex-col gap-spacing-5xl pt-spacing-md lg:px-spacing-3xl pb-spacing-3xl">
        {/* <div className='flex flex-col gap-spacing-4xl h-full'> */}
        <div className="flex flex-col gap-spacing-lg">
          <div className="flex flex-col px-spacing-xl lg:px-spacing-0 gap-spacing-lg">
            <p className="font-semibold text-2xl text-grayLight-900">Skonfiguruj zamówienie</p>
            <p className="font-normal text-grayLight-600" style={{ fontSize: '16px' }}>
              Wybierz dowolne posiłki na każdy dzień dodając je do koszyka, następnie przejdź do
              koszyka i opłać zamówienie.
            </p>
          </div>
        </div>
        <div className="w-full">
          <CustomHorizontalTabs
            onChange={(value) => {
              setSelectedUserId(value);
            }}
            data={
              parentDashboardContext
                ? Array.from(
                    new Map(
                      parentDashboardContext.tenants
                        .flatMap((tenant) => tenant.consumers)
                        .map((consumer) => [
                          consumer.consumerDto.id,
                          {
                            label: isMobile
                              ? `${consumer.consumerDto.firstName}`
                              : `${consumer.consumerDto.firstName} ${consumer.consumerDto.lastName}`,
                            value: consumer.consumerDto.id
                          }
                        ])
                    ).values()
                  )
                : null
            }
          />
        </div>
        <div className="flex flex-col pt-spacing-4xl lg:pt-0 gap-spacing-4xl h-full">
          <WideDateInput
            setDate={setDate}
            maxDate={parentOrderContext?.maxOrderDate}
            minDate={parentOrderContext?.minOrderDate}
          />
          <div
            className="flex flex-col gap-spacing-lg overflow-y-auto"
            style={{ paddingBottom: '400px' }}>
            {selectedOrder ? (
              <>
                {selectedOrder.purchasableItems && selectedOrder.purchasableItems.length > 0 ? (
                  selectedOrder.purchasableItems.map((item) => (
                    <div
                      key={item.stakeId}
                      className="flex bg-white flex-col rounded-xl p-spacing-xl gap-spacing-sm shadow-[0px_4px_6px_-2px_#10182808,0px_12px_16px_-4px_#10182814]">
                      {item.alreadyBoughtCount > 0 && (
                        <div>
                          <CustomBadge size="sm" color="blue">
                            Uwaga, już zakupiono ten posiłek na ten dzień! (x
                            {item.alreadyBoughtCount})
                          </CustomBadge>
                        </div>
                      )}
                      <div className="flex justify-between">
                        <div>
                          <p className="text-grayLight-900 text-lg font-semibold">{item.name}</p>
                        </div>
                        <div className="flex flex-col gap-spacing-md items-end justify-between">
                          <div className="flex gap-spacing-xs">
                            {item.originalPrice === item.price || (
                              <p className="text-grayLight-500 text-xl font-medium line-through">
                                {calculatePrice(item.originalPrice, null)}
                              </p>
                            )}
                            <p className="text-grayLight-900 text-xl font-semibold">
                              {calculatePrice(item.price, null)}
                            </p>
                          </div>
                          <div
                            className="flex flex-col items-end"
                            style={{ marginBottom: '-10px' }}>
                            <div>
                              <CustomChakraButton
                                buttonProps={{
                                  onClick: () => {
                                    addItemToBasket({
                                      tenantId: tenantId || 0,
                                      consumerId: selectedConsumerId || 0,
                                      orderedItems: [
                                        {
                                          purchasableItem: {
                                            name: item.name,
                                            meals: item.meals,
                                            price: item.price,
                                            originalPrice: item.originalPrice,
                                            stakeId: item.stakeId,
                                            alreadyBoughtCount: item.alreadyBoughtCount,
                                            orderCount: item.orderCount,
                                            stakeLimitLeft: item.stakeLimitLeft,
                                            itemPurchaseLimit: item.itemPurchaseLimit
                                          },
                                          count: 1,
                                          when: formatDateForComparison(
                                            date.year,
                                            date.month,
                                            date.day
                                          )
                                        }
                                      ],
                                      useCredit: false
                                    });
                                  },
                                  disabled:
                                    item.itemPurchaseLimit === null ||
                                    item.itemPurchaseLimit <= 0 ||
                                    item.alreadyBoughtCount === item.itemPurchaseLimit
                                }}
                                size="md"
                                hierarchy="secondaryColor"
                                iconPosition="onlyText">
                                Dodaj do koszyka
                              </CustomChakraButton>
                            </div>
                            {item.itemPurchaseLimit !== null && item.itemPurchaseLimit <= 0 && (
                              <p className="text-sm font-normal text-error-600">
                                Osobisty Limit zakupowy osiągnięty, nie można dodać elementu do
                                koszyka
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-spacing-md">
                        {item.meals.map((meal) => (
                          <div key={meal.courseName} className="flex flex-col gap-spacing-xs">
                            <div className="border-b box-border border-brand-300">
                              <p className="text-grayLight-500 text-sm font-normal">
                                {meal.courseName}
                              </p>
                            </div>
                            <div className="flex gap-spacing-md">
                              <div>
                                <p
                                  className="text-grayLight-600 font-semibold"
                                  style={{ fontSize: '16px' }}>
                                  {meal.mealDto?.name}
                                </p>
                              </div>
                              <div className="flex gap-spacing-md">
                                {meal.mealDto?.allergens.map((allergen) => (
                                  <CustomBadge
                                    key={allergen.formalNumber}
                                    children={allergen.formalNumber}
                                    size="sm"
                                    color="brand"
                                  />
                                ))}
                              </div>
                            </div>
                            <div>
                              <p className="text-grayLight-600 font-normal text-sm">
                                {meal.mealDto?.description}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="m-auto">
                    Brak możliwości składania zamówień na wybrany dzień. <br /> Powód:{' '}
                    <b>{selectedOrder.comment}</b>
                  </p>
                )}
              </>
            ) : (
              <p className="m-auto">Brak możliwości składania zamówień na wybrany dzień.</p>
            )}
          </div>
          {/* </div> */}
        </div>
      </div>
      <div className="hidden md:flex md:w-[750px]"></div>
    </section>
  );
};

export default OrderOnDays;
