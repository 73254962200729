import React, { useState, useEffect, useRef } from 'react';
import IconComponent from '../../IconComponent/IconComponent';

type Option = {
  label: string;
  value: number;
};

type SelectProps = {
  options: Option[];
  selected: number[];
  setSelected: (selected: number[]) => void;
};

export const MultipleSelect = ({ options, selected, setSelected }: SelectProps) => {
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const handleSelectAll = () => {
    if (selected.length === options.length) {
      setSelected([]);
    } else {
      setSelected(options.map((opt) => opt.value));
    }
  };

  const handleSelect = (value: number) => {
    setSelected(
      selected.includes(value) ? selected.filter((v) => v !== value) : [...selected, value]
    );
  };

  const handleRemove = (value: number) => {
    setSelected(selected.filter((v) => v !== value));
  };

  const filteredOptions = options.filter((opt) =>
    opt.label.toLowerCase().includes(search.toLowerCase())
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full max-w-md" ref={selectRef}>
      <div
        className="flex items-center justify-between border border-gray-300 rounded-lg p-2 bg-white cursor-pointer"
        onClick={() => setIsOpen((prev) => !prev)}>
        <div className="flex gap-2 items-center flex-wrap">
          {selected.length === options.length ? (
            <span className="flex items-center gap-2 px-[10px] py-1 border border-gray-300 rounded-md text-sm">
              <span className="max-w-28 overflow-hidden text-nowrap text-ellipsis">
                Wszystkie ({selected.length})
              </span>
              <button
                type="button"
                className="text-gray-400 hover:text-gray-700"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelected([]);
                }}>
                <IconComponent className="w-2 h-2" iconName="xClose" />
              </button>
            </span>
          ) : selected.length === 1 ? (
            <span className="text-gray-800 text-sm font-medium">
              {options.find((opt) => opt.value === selected[0])?.label}
            </span>
          ) : selected.length > 1 ? (
            selected.map((value) => (
              <span
                key={value}
                className="flex items-center gap-2 px-[10px] py-1 border border-gray-300 rounded-md text-sm">
                <span className="max-w-28 overflow-hidden text-nowrap text-ellipsis">
                  {options.find((opt) => opt.value === value)?.label}
                </span>
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-700"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemove(value);
                  }}>
                  <IconComponent className="w-2 h-2" iconName="xClose" />
                </button>
              </span>
            ))
          ) : (
            <span className="text-gray-500">Wybierz oddział lub oddziały</span>
          )}
        </div>
        <span className="ml-2 text-gray-500">
          <IconComponent iconName="chevronDown" />
        </span>
      </div>

      {isOpen && (
        <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-auto z-10">
          <div className="p-2">
            <input
              type="text"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2"
            />
          </div>

          <ul>
            <li
              className={`flex items-center justify-between px-4 py-2 cursor-pointer hover:bg-gray-100 ${
                selected.length === options.length ? 'bg-amber-50 hover:bg-amber-100' : ''
              }`}
              onClick={handleSelectAll}>
              <span>Zaznacz wszystkie</span>
              <div className="flex items-center">
                {selected.length === options.length && (
                  <span className="h-5 w-5 text-yellow-400 flex items-center justify-center">
                    <IconComponent iconName="check" color="currentColor" />
                  </span>
                )}
              </div>
            </li>

            {filteredOptions.map((opt) => {
              const isSelected = selected.includes(opt.value);
              return (
                <li
                  key={opt.value}
                  className={`flex items-center justify-between px-4 py-2 cursor-pointer hover:bg-gray-100 ${
                    isSelected ? 'bg-amber-50 hover:bg-amber-100' : ''
                  }`}
                  onClick={() => handleSelect(opt.value)}>
                  <span>{opt.label}</span>
                  <div className="flex items-center">
                    {isSelected && (
                      <span className="h-5 w-5 text-yellow-400 flex items-center justify-center">
                        <IconComponent iconName="check" color="currentColor" />
                      </span>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
