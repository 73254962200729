import { HttpStatusCode } from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../../../context/AlertProvider";
import { useApi } from "../../../../../../context/ApiProvider";
import { useKidConsumerContext } from "../../../../../../context/KidConsumerProvider";
import useAuth from "../../../../../../hooks/useAuth";
import CustomAlert from "../../../../../CustomAlert/CustomAlert";
import CustomChakraButton from "../../../../../CustomChakraButton/CustomChakraButton";
import IconComponent from "../../../../../IconComponent/IconComponent";
import AddParent from "../../../Parents/ParentsTable/AddParent/AddParent";
import LinkParent from "./LinkParent/LinkParent";
import SingleParentTab from "./SingleParentTab/SingleParentTab";

const KidParents = () => {
  const { apiTenantConsumerController } = useApi();
  const { auth } = useAuth();
  const { setShow: setShowAlert, setAlertProperties} = useAlertContext();
  const {kid, fetchKidConsumer} = useKidConsumerContext();
  const { id: tenantId, kidId } = useParams<{
    id: string,
    kidId: string
  }>();
  const [ unassignParentId, setUnassignParentId ] = React.useState<number>();
  const [ popUp, setPopUp ] = React.useState<{
    link: boolean,
    delete: boolean,
    add: boolean,
  }>({
    link: false,
    delete: false,
    add: false
  });

  const unassignParent = async (prentId: number) => {
    setUnassignParentId(prentId);
    setPopUp((prev) => ({...prev, delete: true}));
  }

  return (
      <div className='flex w-full h-full justify-between'>
        <div className='flex flex-col gap-spacing-xl flex-wrap basis-1/3'>
          {kid.parents.map(parent => <SingleParentTab key={parent.id} parent={parent} unassignParent={unassignParent} />)}
        </div>
        <div className='flex flex-row gap-2'>
          <div>
            <CustomChakraButton size="md" hierarchy="primary" iconPosition="left" icon="plus" buttonProps={{
              type: 'button',
              onClick: () => {
                setPopUp((prev) => ({...prev, add: true}))
              }
            }}>
              Dodaj konto rodzica
            </CustomChakraButton>
          </div>
          <div>
            <CustomChakraButton size="md" hierarchy="primary" iconPosition="left" icon="plus" buttonProps={{
              type: 'button',
              onClick: () => {
                setPopUp((prev) => ({...prev, link: true}))
              }
            }}>
              Powiąż z istniejącym rodzicem
            </CustomChakraButton>
          </div>
        </div>
        {popUp.add && (
          <AddParent
            isOpen={popUp.add}
            onClose={() => {
              setPopUp((prev) => ({...prev, add: false}));
              fetchKidConsumer();
            }}
            kidId={Number(kidId)}
          />
        )}
        {popUp.link && (
          <LinkParent
            isOpen={popUp.link}
            consumerId={Number(kidId)}
            onClose={() => {
              setPopUp((prev) => ({...prev, link: false}));
              fetchKidConsumer();
            }}
            tenantId={Number(tenantId)}
          />
        )}
        {popUp.delete && (
          <CustomAlert
            header="Rozłączanie dziecka i rodzica"
            content="Czy na pewno odłączyć dziecko od rodzica?"
            confirmButton={{
              iconPosition: 'onlyText',
              size: 'lg',
              hierarchy: 'warning'
            }}
            confirmButtonText="Usuń"
            onConfirmAction={
              async () => {
                try{
                  const response = await apiTenantConsumerController('unassign-consumer-from-parent').post(`/${tenantId}/${kidId}/${unassignParentId}`);
                  if(response.status === HttpStatusCode.Ok){
                    setAlertProperties({
                      timeout: 2000,
                      title: 'Sukces',
                      description: `Poprawnie rozłączono rodzica od dziecka`,
                      status: 'success',
                    });
                    setShowAlert(true);
                    setPopUp((prev) => ({...prev, delete: false}))
                    setUnassignParentId(0);
                    fetchKidConsumer();
                  }
                } catch (error: any) {
                  console.error(error)
                  setAlertProperties({
                    timeout: 2000,
                    title: 'Błąd',
                    description: `Nie udało się rozłączyć rodzica od dziecka`,
                    status: 'error',
                  });
                  setShowAlert(true);
                }}
            }
            cancelButton={{
              iconPosition: 'onlyText',
              size: 'lg',
              hierarchy: 'secondaryGray'
            }}
            cancelButtonText="Anuluj"
            onCancelAction={() => {
              setPopUp((prev) => ({...prev, delete: false}))
              setUnassignParentId(0);
              fetchKidConsumer();
            }}
            handleOpen={popUp.delete}
            icon={
              <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
                <IconComponent iconName="trash" />
              </div>
            }
          />
          )}
      </div>
      )
};

      export default KidParents;