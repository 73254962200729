import {
  TableContainer,
  Th,
  Thead,
  useDisclosure,
  Menu,
  MenuButton,
  Box,
  MenuList,
  MenuItem
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useAlertContext } from "../../../../context/AlertProvider";
import { useApi } from '../../../../context/ApiProvider';
import { useSideNavContext } from '../../../../context/SideNavProvider';
import CustomAlert from '../../../CustomAlert/CustomAlert';
import { DataTable } from '../../../DataTable/DataTable';
import IconComponent from '../../../IconComponent/IconComponent';
import { TenantInterface, TenantSimplifiedInterface } from '../../../../shared/type/tenant.type';
import AddNewBranch from '../AddNewBranch/AddNewBranch';

type BranchType = {
  id: string;
  name: string;
  city: string;
  contact: {
    name: string;
    email: string;
    phone: string;
  };
  company: string;
};

const columnHelper = createColumnHelper<BranchType>();

const defaultAlert = {
  isOpen: false,
  id: 0,
  name: ''
};

const BranchesTable = () => {
  const { toggleShowNav } = useSideNavContext();
  const { apiTenantController } = useApi();
  const { isOpen, onClose } = useDisclosure();
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext();
  const [branchData, setBranchData] = useState<BranchType[] | []>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [deleteElement, setDeleteElement] = useState<{
    isOpen: boolean;
    id: number;
    name: string;
  }>(defaultAlert);

  const [sendActivationEmails, setSendActivationEmails] = useState<{
    isOpen: boolean;
    id: number;
    name: string;
  }>(defaultAlert);

  const columns = [
    columnHelper.accessor('id', {
      cell: (info) => info.getValue(),
      header: 'ID'
    }),
    columnHelper.accessor('name', {
      cell: (info) => {
        const { id } = info.row.original;
        const name = info.getValue();
        return <Link to={`id/${id}`}>{name}</Link>;
      },
      header: 'Nazwa'
    }),
    columnHelper.accessor('city', {
      cell: (info) => {
        const cityName = info.getValue();
        return <div className="text-sm font-medium">{cityName}</div>;
      },
      header: 'Miasto'
    }),
    columnHelper.accessor('contact', {
      cell: (info) => {
        const contact = info.getValue();
        return (
          <div className="contact-info text-sm">
            <div className="font-medium">{contact.name}</div>
            <div className="font-normal">{contact.email}</div>
            <div className="font-normal">{contact.phone}</div>
          </div>
        );
      },
      header: 'Kontakt'
    }),
    columnHelper.accessor('company', {
      cell: (info) => {
        const company = info.getValue();
        return <div className="text-sm font-normal underline">{company}</div>;
      },
      header: 'Firma'
    }),
    columnHelper.accessor('company', {
      cell: (info) => {
        const { id, name } = info.row.original;
        return (
          <div>
            <Menu>
              <MenuButton
                as={Box}
                aria-label="Options"
                className="cursor-pointer flex items-center"
              >
                <IconComponent iconName="verticalDot" />
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    setDeleteElement({
                      id: Number(id),
                      name,
                      isOpen: true
                    });
                  }}
                >
                  Usuń
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSendActivationEmails({
                      id: Number(id),
                      name,
                      isOpen: true
                    });
                  }}
                >
                  Wyślij maile aktywacyjne
                </MenuItem>
                <MenuItem>
                  <Link to={`id/${id}`}>Edytuj&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Link>
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
        );
      },
      header: ''
    })
  ];

  const fetchBranches = async () => {
    try {
      const response: AxiosResponse<any, TenantSimplifiedInterface[]> =
        await apiTenantController('').get('');
      return response.data;
    } catch (error) {
      console.warn(error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      const miniResponse = await fetchBranches();
      if (miniResponse) {
        const fullData = await Promise.all(
          miniResponse.map(async (item: TenantSimplifiedInterface) => {
            const response: AxiosResponse<any, TenantInterface> = await apiTenantController('').get(
              `/${item.id}`
            );
            const { id, name, city, contactPerson, email, phone } = response.data;
            return {
              id,
              name,
              city,
              contact: {
                name: contactPerson,
                email,
                phone
              },
              company: item.parentCompanyName
            };
          })
        );
        console.warn(fullData);
        setBranchData(fullData);
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
    fetchData();
  };

  useEffect(() => {
    //set as true because we don't have button to unhide sidenav
    toggleShowNav(true);
    fetchData();
  }, []);

  return (
    <div className="Branches__tableContainer h-full flex flex-col justify-start lg:p-spacing-4xl">
      <TableContainer h={800} overflowY='scroll' className="Branches__table lg:p-spacing-3xl  bg-white rounded-lg">
        <DataTable
          data={branchData}
          columns={columns}
          isLoading={isLoading}
          extraThead={
            <Thead>
              <Th colSpan={columns.length}>
                <div className="w-full flex items-center justify-between">
                  <p className="text-base font-semibold">Lista oddziałów</p>
                  <div className="hidden lg:block">
                    {/* <CustomChakraButton hierarchy='primary' size='md' icon='plus' iconPosition='left' buttonProps={{ */}
                    {/*   onClick: onOpen, */}
                    {/* }}>Dodaj oddział</CustomChakraButton> */}
                  </div>
                </div>
              </Th>
            </Thead>
          }
        />
      </TableContainer>
      <div className="bg-white mt-spacing-lg h-20 p-spacing-xl lg:hidden">
        {/* <button */}
        {/*   type="button" */}
        {/*   className="rounded bg-brand-300 w-full h-full border-brand-300 py-2.5 px-4 font-semibold text-base flex items-center justify-center" */}
        {/* > */}
        {/*   <IconComponent iconName="plus" className="pr-3 h-5 w-5" /> */}
        {/*   Dodaj oddział */}
        {/* </button> */}
      </div>
      <AddNewBranch isOpen={isOpen} onClose={handleClose} />
      <CustomAlert
        handleOpen={deleteElement.isOpen}
        icon={<IconComponent iconName="trash" />}
        header={`Usunąć Oddział ${deleteElement?.name}?`}
        content="Usunięcie oddziału jest nieodwracalne i wiążę się z utratą danych."
        cancelButtonText="Anuluj"
        confirmButtonText="Usuń"
        cancelButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'secondaryGray'
        }}
        confirmButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'primary'
        }}
        onCancelAction={() => {
          setDeleteElement(defaultAlert);
        }}
        onConfirmAction={async () => {
          try {
            await apiTenantController('').delete(`/${deleteElement?.id}`);
            setAlertProperties({
              status: 'success',
              title: 'Poprawnie usunięto oddział',
              timeout: 2000
            })
          } catch (error) {
            setAlertProperties({
              status: 'error',
              title: 'Błąd usuwania oddziału',
              timeout: 5000
            })
          } finally {
            setShowAlert(true);
            fetchData();
            setDeleteElement(defaultAlert);
          }
        }}
      />
      <CustomAlert
        handleOpen={sendActivationEmails.isOpen}
        icon={<IconComponent iconName="trash" />}
        header={`Wysłać maile aktywacyjne do Oddział ${sendActivationEmails?.name}?`}
        content="Do wszystkich nieaktywnych użytkowników (rodzice, nauczyciele, pracownicy) zostaną wysłane maile aktywacyjne. Operacja może zając dłuższy czas."
        cancelButtonText="Anuluj"
        confirmButtonText="Wyślij maile aktywacyjne"
        cancelButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'secondaryGray'
        }}
        confirmButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'primary'
        }}
        onCancelAction={() => {
          setSendActivationEmails(defaultAlert);
        }}
        onConfirmAction={async () => {
          try {
            await apiTenantController('').post(`/send-activation-emails/${sendActivationEmails?.id}`);
            setAlertProperties({
              status: 'success',
              title: 'Poprawnie wysłano maile aktywacyjne',
              timeout: 5000
            })
          } catch (error) {
            setAlertProperties({
              status: 'error',
              title: 'Błąd wysyłania maili aktywacyjych do oddziału',
              timeout: 5000
            })
          } finally {
            setShowAlert(true);
            fetchData();
            setSendActivationEmails(defaultAlert);
          }
        }}
      />
    </div>
  );
};

export default BranchesTable;
