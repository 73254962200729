import './TenantAdminDashboard.css';
import React, { useEffect } from 'react';
import { MenuDivider, MenuItem } from '@chakra-ui/react';
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import WideHeader from '../../component/Headers/WideHeader/WideHeader';
import Absences from '../../component/TenantAdminComponents/Absences/Absences';
import AdminSettings from '../../component/TenantAdminComponents/AdminSettings/AdminSettings';
import Kitchen from '../../component/TenantAdminComponents/Kitchen/Kitchen';
import MailBox from '../../component/TenantAdminComponents/MailBox/MailBox';
import SingleAdminMessage from '../../component/TenantAdminComponents/MailBox/Messages/SingleAdminMessage/SingleAdminMessage';
import Reports from '../../component/TenantAdminComponents/Reports/Reports';
import School from '../../component/TenantAdminComponents/School/School';
import Users from '../../component/TenantAdminComponents/Users/Users';
import { useApi } from '../../context/ApiProvider';

import { SideMenuOptionInterface } from '../../shared';
import IconComponent from '../../component/IconComponent/IconComponent';
import useAuth from '../../hooks/useAuth';
import { useSideNavContext } from '../../context/SideNavProvider';

const TenantAdminDashboard = () => {
  const { id: tenant } = useParams<{ id: string }>();
  const { logout } = useAuth();
  const { setNavOptions, toggleShowNav, setToggleMode } = useSideNavContext();
  const { apiPrivateSecurityController } = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    setToggleMode('minimal');
  }, []);

  useEffect(() => {
    setNavOptions(menuOptions);
  }, [tenant]);

  const menuOptions: SideMenuOptionInterface[] = [
    // {
    //   header: 'Strona Główna',
    //   icon: 'happyHomeIcon',
    //   link: 'test'
    // },

    {
      header: 'Zamówienia',
      icon: 'calendar',
      link: `id/${tenant}/absences`
    },
    {
      header: 'Kuchnia',
      icon: 'cutlery',
      children: [
        {
          header: 'Jadłospis',
          link: `id/${tenant}/kitchen/food-menu/meal-plan`
        },
        {
          header: 'Alergeny',
          link: `id/${tenant}/kitchen/allergens`
        },
        {
          header: 'Podsumowanie',
          link: `id/${tenant}/kitchen/summary`
        }
      ]
    },
    {
      header: 'Użytkownicy',
      icon: 'users',
      children: [
        {
          header: 'Dzieci',
          link: `id/${tenant}/users/children/children-table`
        },
        {
          header: 'Rodzice',
          link: `id/${tenant}/users/parents/parents-table`
        },
        {
          header: 'Nauczyciele',
          link: `id/${tenant}/users/teachers/teachers-table`
        },
        {
          header: 'Pracownicy',
          link: `id/${tenant}/users/employees/employees-table`
        }
      ]
    },
    {
      header: 'Raporty',
      icon: 'file',
      link: `id/${tenant}/reports`
    },
    {
      header: 'Wiadomości',
      icon: 'message',
      link: `id/${tenant}/mail-box/messages`
    },
    {
      header: 'Ustawienia',
      icon: 'graduationHat',
      children: [
        {
          header: `Klasy`,
          link: `id/${tenant}/school/classes`
        },
        {
          header: 'Dni wolne / blokady zamówień',
          link: `id/${tenant}/school/free-days`
        },
        {
          header: 'System sprzedaży',
          link: `id/${tenant}/kitchen/sales-system/servings`
        },
        {
          header: 'Instytucje dofinansowujące',
          link: `id/${tenant}/school/co-financing-institutions`
        },
        {
          header: 'Regulaminy',
          link: `id/${tenant}/school/school-statutes`
        },
        {
          header: 'Administratorzy',
          link: `id/${tenant}/users/administrators/administrators-table`
        },
        {
          header: 'System',
          link: `id/${tenant}/school/school-settings`
        }
      ]
    }
  ];

  const menuList = (
    <>
      <MenuItem
        icon={
          <div className="Header__iconContainer">
            <IconComponent iconName="settings" className="w-full h-full" />
          </div>
        }
        onClick={function () {
          navigate('/tenantAdmin/settings');
        }}>
        Ustawienia
      </MenuItem>
      <MenuDivider />
      <MenuItem
        icon={
          <div className="Header__iconContainer">
            <IconComponent iconName="logOutDoor" className="w-full h-full" />
          </div>
        }
        onClick={async function () {
          await apiPrivateSecurityController('logout').get('');
          logout();
        }}>
        Wyloguj się
      </MenuItem>
    </>
  );

  useEffect(() => {
    toggleShowNav(true);
    setNavOptions(menuOptions);
  }, []);

  return (
    <section className="TenantAdminDashboard flex flex-row w-full h-screen">
      <div className="TenantAdminDashboard__contextContainer flex flex-col w-full h-screen">
        <div className="TenantAdminDashboard__headerContainer border-b border-brand-600">
          <WideHeader showLogo={false} dropdownMenuList={menuList} />
        </div>
        <div className="ParentDashboard__greetingsContainer flex-grow min-h-0 overflow-auto overflow-x-hidden">
          <div className="ParentDashboard__contextContainer">
            <Routes>
              <Route path="/id/:id/school/*" element={<School />} />
              <Route path="/id/:id/kitchen/*" element={<Kitchen />} />
              <Route path="/id/:id/absences/*" element={<Absences />} />
              <Route path="/settings/*" element={<AdminSettings />} />
              <Route path="/id/:id/users/*" element={<Users />} />
              <Route path="/id/:id/reports/*" element={<Reports />} />
              <Route
                path="/id/:id/mail-box/messages/message/:id/*"
                element={<SingleAdminMessage />}
              />
              <Route path="/id/:id/mail-box/*" element={<MailBox />} />
            </Routes>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TenantAdminDashboard;
