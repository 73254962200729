import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React, { useEffect, useState } from "react";
import { useAlertContext } from "../../../../../../../context/AlertProvider";
import { useApi } from "../../../../../../../context/ApiProvider";
import CustomChakraButton from "../../../../../../CustomChakraButton/CustomChakraButton";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  tenantId: number;
  consumerId: number;
}

interface SimpleParentsInterface {
  id: number;
  firstName: string;
  lastName: string;
}

const LinkParent = ({ isOpen, onClose, tenantId, consumerId }: Props) => {
  const { apiTenantParentController, apiTenantConsumerController } = useApi();
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext();

  const [searchPhrase, setSearchPhrase] = useState<string>("");
  const [typedPhrase, setTypedPhrase] = useState<string>("");

  const [simpleParents, setSimpleParents] = useState<SimpleParentsInterface[]>([]);
  const [selectedParent, setSelectedParent] = useState<SimpleParentsInterface | null>(null);

  const fetchAvailableParents = async () => {
    try {
      const response = await apiTenantParentController("simple-parents-by-search-phrase").get(
        `/${tenantId}`,
        { params: { searchPhrase } }
      );
      setSimpleParents(response.data);
    } catch (e) {
      setAlertProperties({
        status: "error",
        title: "Błąd pobierania rodziców",
        timeout: 5000
      });
      setShowAlert(true);
    }
  };

  const linkParent = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!selectedParent) return;

    try {
      await apiTenantConsumerController("assign-consumer-to-parent").post(
        `/${tenantId}/${consumerId}/${selectedParent.id}`
      );

      setAlertProperties({
        timeout: 2000,
        title: "Poprawnie przypisano dziecko",
        status: "success"
      });
      setShowAlert(true);
      onClose();
    } catch (error) {
      setAlertProperties({
        timeout: 2000,
        title: "Błąd przypisywania dziecka",
        status: "error"
      });
      setShowAlert(true);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchPhrase(typedPhrase);
    }, 300);

    return () => clearTimeout(handler);
  }, [typedPhrase]);

  useEffect(() => {
    if (searchPhrase.length >= 3) {
      fetchAvailableParents();
    } else {
      setSimpleParents([]);
    }
  }, [searchPhrase]);

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <form onSubmit={linkParent}>
        <ModalOverlay
          sx={{
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        />
        <ModalContent>
          <ModalHeader className="flex">
            <div className="flex flex-col text-grayLight-900 border-r border-grayLight-200">
              <h2 className="font-semibold text-lg">Powiąż z istniejącym rodzicem</h2>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="flex flex-col gap-spacing-xl">
            <div className="flex flex-col gap-spacing-md">
              <label className="text-sm font-medium text-grayLight-700">
                Wpisz co najmniej 3 znaki, żeby wyszukać rodziców do przypisania
              </label>
              <Select
                colorScheme="gray"
                placeholder="Wybierz"
                isSearchable={true}
                isMulti={false}
                inputValue={typedPhrase}
                onInputChange={(value, action) => {
                  if (action.action === "input-change") {
                    setTypedPhrase(value);
                    setSelectedParent(null);
                  }
                }}
                value={selectedParent ? { label: `${selectedParent.firstName} ${selectedParent.lastName}`, value: selectedParent.id } : null}
                onChange={(option) => {
                  if (option) {
                    setSelectedParent(simpleParents.find(p => p.id === option.value) || null);
                    setTypedPhrase("");
                  }
                }}
                options={simpleParents.map((unit) => ({
                  label: `${unit.firstName} ${unit.lastName}`,
                  value: unit.id
                }))}
              />
            </div>
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                onClick: onClose
              }}
            >
              Anuluj
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                type: "submit",
                disabled: !selectedParent
              }}
            >
              Przypisz rodzica
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default LinkParent;