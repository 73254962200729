import { AxiosResponse, HttpStatusCode } from 'axios';
import { Select } from 'chakra-react-select';
import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Divider
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useAlertContext } from '../../../../../../context/AlertProvider';
import { useApi } from '../../../../../../context/ApiProvider';
import { OrganisationUnitInterface } from '../../../../../../shared';
import CustomChakraButton from '../../../../../CustomChakraButton/CustomChakraButton';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  kidIds: number[];
  afterAction?: () => void;
}

const MoveKid = ({ isOpen, handleClose, kidIds, afterAction }: Props) => {
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext();
  const { apiTenantOrganisationUnitController, apiTenantConsumerController } = useApi();
  const { id: tenantId } = useParams<{ id: string }>();
  const [organisationUnits, setOrganisationUnits] = useState<OrganisationUnitInterface[]>([]);

  const [targetOrganisationUnit, setTargetOrganisationUnit] = useState<number>();

  const handleMoveKid = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    try {
      const response = await apiTenantConsumerController('assign-children-to-org-unit').post('', {
        tenantId: tenantId,
        targetOrgId: targetOrganisationUnit,
        consumerIds: kidIds
      });
      if (response.status === HttpStatusCode.Ok) {
        setAlertProperties({
          status: 'success',
          title: 'Sukces',
          description: `Poprawnie przeniesiono użytkowników ${kidIds.length > 1 ? 'użytkowników' : 'użytkownika'}`,
          timeout: 2000
        });
        setShowAlert(true);
        handleClose();
        afterAction && afterAction();
      } else {
        setAlertProperties({
          status: 'error',
          title: 'Błąd',
          description: `Błąd przenoszenia ${kidIds.length > 1 ? 'użytkowników' : 'użytkownika'}`,
          timeout: 2000
        });
        setShowAlert(true);
      }
    } catch (error: any) {
      setAlertProperties({
        status: 'error',
        title: 'Błąd',
        description: `Błąd przenoszenia ${kidIds.length > 1 ? 'użytkowników' : 'użytkownika'} : ${error.response.data.errorMessage}`,
        timeout: 2000
      });
      setShowAlert(true);
    }
  };

  const fetchOrganisationUnits = async () => {
    try {
      const response: AxiosResponse<OrganisationUnitInterface[]> =
        await apiTenantOrganisationUnitController('').get(`/${tenantId}`);
      setOrganisationUnits(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchOrganisationUnits();
  }, [tenantId]);

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleClose} size="xl" isCentered>
      <form onSubmit={handleMoveKid}>
        <ModalOverlay
          sx={{
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col">
            <h2 className="font-semibold text-lg">Przenieś użytkowników ({kidIds.length})</h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-3xl">
            <div className="flex flex-col gap-spacing-md">
              <label className="text-sm font-medium text-grayLight-700">Klasa</label>
              <Select
                colorScheme="gray"
                placeholder="Wybierz"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setTargetOrganisationUnit(selectedOption.value);
                  }
                }}
                options={organisationUnits.map((unit) => ({
                  ...unit,
                  label: unit.name,
                  value: unit.id
                }))}
              />
            </div>
          </ModalBody>
          <Divider className="pt-spacing-2xl pb-spacing-2xl" />
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: 'grow',
                onClick: handleClose
              }}>
              Zamknij
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: 'grow',
                type: 'submit'
              }}>
              Przenieś
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default MoveKid;
