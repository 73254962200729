import { useCallback, useEffect, useState } from 'react';
import { AxiosResponse, HttpStatusCode } from 'axios';
import { useParams } from 'react-router-dom';

import { useAlertContext } from '../../../../../../context/AlertProvider';
import { useApi } from '../../../../../../context/ApiProvider';
import { MassOrderContextInterface } from '../../../../../../shared/type/massOrder.type';

type useAddOrderProps = {
  consumerIds: number[];
  handleClose: () => void;
  afterAction?: () => void;
};

type MassOrderFlowSteps = 'add-order' | 'order-summary';

type OrderPosition = {
  stakeName: string;
  consumerCount: number;
  countPerDay: number;
  dayCount: number;
  together: number;
};

export type OrderSummaryResponseType = {
  errorMessages: string[];
  orderIds: number[];
  orderPositions: OrderPosition[];
  success: boolean;
};

export const useAddOrder = ({ consumerIds, handleClose, afterAction }: useAddOrderProps) => {
  const [currentStep, setCurrentStep] = useState<MassOrderFlowSteps>('add-order');
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext();
  const { apiMassOrderController } = useApi();
  const { id: tenantId } = useParams<{ id: string }>();

  const [massOrderContext, setMassOrderContext] = useState<MassOrderContextInterface | null>(null);
  const [stakesCount, setStakesCount] = useState<{ stakeId: number; count: number }[]>([]);
  const [date, setDate] = useState<{ startDate: string; endDate: string }>({
    startDate: '',
    endDate: ''
  });
  const [loading, setLoading] = useState(true);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [orderSummary, setOrderSummary] = useState<OrderSummaryResponseType | undefined>(undefined);

  const handleNextStep = () => {
    setCurrentStep('order-summary');
  };

  const handlePreviousStep = () => {
    setCurrentStep('add-order');
  };

  const fetchMassOrderContext = async () => {
    setLoading(true);

    try {
      const response: AxiosResponse<MassOrderContextInterface> = await apiMassOrderController(
        'order-context'
      ).post('', {
        consumerIds
      });
      setMassOrderContext(response.data);
    } catch (error: any) {
      console.error('Error fetching mass order context:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleMassOrder = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);

    if (formErrors.length > 0) {
      setShowFormErrors(true);
      return;
    }

    try {
      const orderPositions = stakesCount
        .filter((item) => item.count > 0)
        .map((stake) => ({ stakeId: stake.stakeId, count: stake.count }));

      const response: AxiosResponse<OrderSummaryResponseType> = await apiMassOrderController(
        'place-mass-order'
      ).post('', {
        tenantId: Number(tenantId),
        startDate: date.startDate,
        endDate: date.endDate,
        consumerIds,
        orderPositions
      });

      if (response.status === HttpStatusCode.Ok) {
        setOrderSummary(response.data);

        if (!response.data.success || response.data.errorMessages) {
          setFormErrors(response.data.errorMessages);
          setShowFormErrors(true);
        }
        if (!!response.data.success && response.data.errorMessages.length === 0) {
          setAlertProperties({
            status: 'success',
            title: 'Sukces',
            description: 'Zamówienie zostało poprawnie złożone',
            timeout: 5000
          });
          setShowAlert(true);
        }
        handleNextStep();
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.errorMessages?.[0] || 'Error placing order.';
      setAlertProperties({
        status: 'error',
        title: 'Błąd',
        description: `Błąd dodawania zamówienia: ${errorMessage}`,
        timeout: 2000
      });
      setShowAlert(true);
    } finally {
      setLoading(false);
    }
  };

  const changeStackCountValue = (value: number, limit: number | null, stakeId: number) => {
    const changedStakeCount = stakesCount.find((stake) => stake.stakeId === stakeId);

    if (!changedStakeCount || stakesCount.length === 0) {
      setStakesCount((prevStakesCount) => [...prevStakesCount, { stakeId, count: value }]);
      return;
    }

    setStakesCount((prevStakesCount) =>
      prevStakesCount.map((stake) =>
        stake.stakeId === stakeId
          ? {
              ...stake,
              count: Math.max(
                0,
                limit !== null ? Math.min(stake.count + value, limit) : stake.count + value
              )
            }
          : stake
      )
    );
  };

  const validateForm = useCallback(() => {
    const errorsToDisplay: string[] = [];
    if (!date.startDate || !date.endDate) {
      errorsToDisplay.push('Należy wybrać daty');
    } else if (new Date(date.startDate) > new Date(date.endDate)) {
      errorsToDisplay.push('Data końcowa nie może być wcześniejsza niż data początkowa');
    }

    if (!stakesCount.some((stake) => stake.count > 0)) {
      errorsToDisplay.push('Należy wybrać przynajmniej jedną stawkę');
    }

    setFormErrors(errorsToDisplay);

    if (errorsToDisplay.length === 0) {
      setShowFormErrors(false);
    }
  }, [date, stakesCount]);

  useEffect(() => {
    fetchMassOrderContext();
  }, [tenantId, consumerIds]);

  useEffect(() => {
    validateForm();
  }, [date, stakesCount]);

  return {
    massOrderContext,
    stakesCount,
    date,
    setDate,
    handleMassOrder,
    changeStackCountValue,
    loading,
    formErrors,
    showFormErrors,
    orderSummary,
    handlePreviousStep,
    currentStep
  };
};
