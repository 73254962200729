import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';

export const DEFAULT_SUMMARY_FILTER_OPTIONS: SummaryFilterOption[] = [
  { label: 'Według kategorii posiłku', value: 'categories' },
  { label: 'Według stawki', value: 'stakes' }
];

export type SummaryFilterOptionValue = 'categories' | 'stakes';

export type SummaryFilterOption = {
  label: string;
  value: SummaryFilterOptionValue;
};

type SummaryFilterProps = {
  options?: SummaryFilterOption[];
  activeFilter: SummaryFilterOptionValue;
  onFilterChange: (option: SummaryFilterOptionValue) => void;
};

export const SummaryFilter = ({
  options = DEFAULT_SUMMARY_FILTER_OPTIONS,
  activeFilter,
  onFilterChange
}: SummaryFilterProps) => {
  const handleClick = (option: SummaryFilterOptionValue) => {
    onFilterChange(option);
  };

  return (
    <div className="w-full bg-gray-50 p-1 mb-4 border border-gray-200 rounded-lg">
      <div className="flex gap-1 items-center">
        {options &&
          options.map((option) => (
            <Box
              key={option.value}
              onClick={() => handleClick(option.value)}
              className={`
              ${
                activeFilter === option.value
                  ? 'bg-white border border-gray-200 shadow-md'
                  : 'bg-transparent border-transparent'
              }
              px-4 py-2 rounded-md cursor-pointer transition-colors duration-200
            `}>
              {option.label}
            </Box>
          ))}
      </div>
    </div>
  );
};
