import {
  Center,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react';
import { KitchenSummaryItem, SummaryInterface } from '../../../../shared/type/kitchenSummary.type';
import { DEFAULT_SUMMARY_FILTER_OPTIONS, SummaryFilter } from './summary-filter';
import { useMemo, useState } from 'react';

type SummaryTableItemProps = {
  isLoading: boolean;
  kitchenSummaryData?: KitchenSummaryItem;
  hideTitle?: boolean;
  customTitle?: string;
};

export const SummaryTableItem = ({
  isLoading,
  kitchenSummaryData,
  hideTitle,
  customTitle
}: SummaryTableItemProps) => {
  const [activeSummaryFilter, setActiveSummaryFilter] = useState(
    DEFAULT_SUMMARY_FILTER_OPTIONS[0].value
  );
  const isCategoriesSelected = activeSummaryFilter === 'categories';
  const summaryDataToDisplay = useMemo(
    () => (isCategoriesSelected ? kitchenSummaryData?.categories : kitchenSummaryData?.stakes),
    [isCategoriesSelected, kitchenSummaryData]
  );

  return (
    <TableContainer overflowY="auto">
      {!hideTitle && (
        <div className="my-4">
          <Text size="md" fontWeight="bold">
            {customTitle || 'Podsumowanie wybranych oddziałów'}
          </Text>
        </div>
      )}
      <SummaryTable
        mainLabel={isCategoriesSelected ? 'Kategoria posiłku' : 'Stawka'}
        isLoading={isLoading}
        kitchenSummaryData={summaryDataToDisplay}
        filterComponent={
          <SummaryFilter
            activeFilter={activeSummaryFilter}
            onFilterChange={setActiveSummaryFilter}
          />
        }
      />
    </TableContainer>
  );
};

type SummaryTableProps = {
  isLoading: boolean;
  kitchenSummaryData?: SummaryInterface[];
  mainLabel: string;
  filterComponent?: React.ReactNode;
};

export const SummaryTable = ({
  isLoading,
  kitchenSummaryData,
  mainLabel,
  filterComponent
}: SummaryTableProps) => {
  return (
    <Table className="w-full">
      <Thead>
        <Tr>
          <Th padding={0} colSpan={5} border="none">
            {filterComponent && filterComponent}
          </Th>
        </Tr>
      </Thead>
      <Thead className="text-xs bg-white p-1 mb-4">
        <Tr>
          <Th
            className="w-3/4 border border-r-0 rounded-lg rounded-r-none border-gray-200 py-3 px-4"
            textTransform="capitalize"
            fontWeight={400}>
            {mainLabel}
          </Th>
          <Th
            className="border border-x-0 border-gray-200 py-3 px-4"
            textTransform="capitalize"
            fontWeight={400}>
            Zakupione posiłki
          </Th>
          <Th
            className="border border-x-0  border-gray-200 py-3 px-4 "
            textTransform="capitalize"
            fontWeight={400}>
            Nieobecności
          </Th>
          <Th
            className="border border-x-0  bg-amber-50 border-gray-200 py-3 px-4 "
            textTransform="capitalize"
            fontWeight={400}>
            Do wydania
          </Th>
          <Th
            className="border border-l-0 rounded-lg  border-gray-200 py-3 px-4"
            textTransform="capitalize"
            fontWeight={400}>
            Odebrane
          </Th>
        </Tr>
      </Thead>
      <Tbody className="text-sm font-medium text-grayLight-900">
        {isLoading ? (
          <Tr>
            <Td colSpan={5}>
              <Center>
                columns.length
                <Spinner size="xl" />
              </Center>
            </Td>
          </Tr>
        ) : kitchenSummaryData && kitchenSummaryData.length === 0 ? (
          <Tr>
            <Td colSpan={5}>
              <Center>
                <Text>Brak danych</Text>
              </Center>
            </Td>
          </Tr>
        ) : (
          kitchenSummaryData &&
          kitchenSummaryData.map((item, index) => {
            return (
              <Tr
                key={`${item.title}-${index}-${item.absences}`}
                className={`border-t border-x bg-white`}>
                <Td className="px-6 py-5 text-grayLight-600" textTransform="capitalize">
                  {item.title}
                </Td>
                <Td className="px-6 py-5 text-grayLight-600" textTransform="capitalize">
                  {item.totalCount}
                </Td>
                <Td className="px-6 py-5 text-grayLight-600" textTransform="capitalize">
                  {item.absences}
                </Td>
                <Td className="px-6 py-5 text-grayLight-600 bg-amber-50" textTransform="capitalize">
                  <Text fontWeight="bold">{item.toDistribute}</Text>
                </Td>
                <Td className="px-6 py-5 text-grayLight-600" textTransform="capitalize">
                  {item.taken}
                </Td>
              </Tr>
            );
          })
        )}
        <Tr className={`border-t border-x bg-white`}>
          <Td className="px-6 py-8 h-14 text-grayLight-600" colSpan={5}></Td>
        </Tr>
      </Tbody>
    </Table>
  );
};
