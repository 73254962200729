import { Input } from '@chakra-ui/react';
import IconComponent from '../../../../../IconComponent/IconComponent';

import { MassOrderContextInterface } from '../../../../../../shared/type/massOrder.type';

interface AddOrderFormProps {
  date: { startDate: string; endDate: string };
  setDate: React.Dispatch<React.SetStateAction<{ startDate: string; endDate: string }>>;
  massOrderContext: MassOrderContextInterface | undefined;
  stakesCount: { stakeId: number; count: number }[];
  changeStackCountValue: (value: number, limit: number | null, stakeId: number) => void;
}

export const AddOrderForm = ({
  date,
  setDate,
  massOrderContext,
  stakesCount,
  changeStackCountValue
}: AddOrderFormProps) => {
  return (
    <form>
      <div className="flex flex-col gap-spacing-md">
        <div className="mb-2">
          <p className="text-sm font-medium text-grayLight-700">
            Wybierz na jaki okres ma obowiązywać zamówienie:
          </p>
        </div>
        <div className="flex basis-1/2 gap-spacing-xl">
          <div className="flex flex-col gap-spacing-xs">
            <label className="text-sm font-medium text-grayLight-600">Data od</label>
            <Input
              type="date"
              lang="pl"
              required
              name="startDate"
              min={massOrderContext?.minDate}
              value={date.startDate}
              onChange={(e) => {
                setDate((prev) => ({
                  ...prev,
                  startDate: e.target.value
                }));
              }}
            />
          </div>
          <div className="flex flex-col gap-spacing-xs">
            <label className="text-sm font-medium text-grayLight-600">Data do</label>
            <Input
              type="date"
              lang="pl"
              required
              name="endDate"
              max={massOrderContext?.maxDate}
              value={date.endDate}
              onChange={(e) => {
                setDate((prev) => ({
                  ...prev,
                  endDate: e.target.value
                }));
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-spacing-md">
        <div>
          <p className="mt-2 text-sm font-medium text-grayLight-700">
            Wybierz stawkę lub stawki oraz ich dzienny przydział:
          </p>
        </div>
        <div>
          {massOrderContext?.stakes ? (
            <table className="w-full border-collapse">
              <thead className="text-xs  bg-gray-50">
                <tr>
                  <th className="border-gray-300 p-2 flex items-start text-gray-600">
                    <p>Nazwa stawki</p>
                  </th>
                  <th className="w-[100px] border-l border-r border-gray-300 p-2 text-gray-600 text-left">
                    <p>Cena w zł</p>
                  </th>
                  <th className="w-[160px] border-gray-300 p-2 text-gray-600">
                    <p>Ilość na osobę / dzień</p>
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm font-medium text-grayLight-900">
                {massOrderContext.stakes.map((stake, index) => {
                  const stakeCount =
                    stakesCount.find((item) => item.stakeId === stake.id)?.count || 0;

                  return (
                    <tr
                      key={stake.id}
                      className={`border-t ${index % 2 === 0 ? 'bg-brand-50' : ''}`}>
                      <td className="flex items-start pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md font-medium text-grayLight-900">
                        {stake.name}
                      </td>
                      <td className="w-[100px] pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md border-l border-r">
                        {stake.price}
                      </td>
                      <td className="w-[150px] flex justify-center pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md">
                        <div className="flex w-36 h-11 rounded-lg border box-border border-grayLight-300">
                          <div className="basis-1/3 flex items-center justify-center">
                            <button
                              type="button"
                              className="w-full h-full flex items-center justify-center"
                              disabled={stakeCount === 0}
                              onClick={() => {
                                changeStackCountValue(-1, stake.salesLimitForPerson, stake.id);
                              }}>
                              <IconComponent iconName="minus" />
                            </button>
                          </div>
                          <div className="basis-1/3 flex items-center justify-center">
                            {stakeCount}
                          </div>
                          <div className="basis-1/3 flex items-center justify-center">
                            <button
                              type="button"
                              className="w-full h-full flex items-center justify-center"
                              disabled={stakeCount === stake.salesLimitForPerson}
                              onClick={() => {
                                changeStackCountValue(1, stake.salesLimitForPerson, stake.id);
                              }}>
                              <IconComponent iconName="plus" />
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <p className="text-sm">Brak wspólnych stawek dla wybranych konsumentów</p>
          )}
        </div>
      </div>
    </form>
  );
};
