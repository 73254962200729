import { Tooltip } from '@chakra-ui/react';
import React from 'react';
// @ts-ignore
import { CalendarEvent } from 'react-big-calendar';

import { useThemeContext } from '../../../../../context/ThemeProvider';
import IconComponent from '../../../../IconComponent/IconComponent';

export interface CustomDayCellProps {
  date: Date;
  events: CalendarEvent[];
  disabled?: boolean;
}

// const mobileAlert = ''

const CustomDayCell: React.FC<CustomDayCellProps> = ({ date, events, disabled }) => {
  const { isMobile } = useThemeContext();

  const eventList = events.map((event, idx) => {
    if (!event.isFixed && !event.isInBasket) return;

    return (
      <div
        key={idx}
        className={
          isMobile
            ? 'flex h-10 gap-spacing-xxs items-center'
            : 'flex gap-spacing-xxs items-center pt-spacing-xxs pr-spacing-md pb-spacing-xxs pl-spacing-sm '
        }
        style={{ zIndex: 1 }}>
        <div className="flex items-center justify-center">
          {event.isFixed && !!event.workingDay && (
            <IconComponent
              iconName="checkCircle"
              color="#4E5BA6"
              className="hidden lg:flex w-2.5 h-2.5"
            />
          )}
          {event.isInBasket && !!event.workingDay && (
            <IconComponent iconName="dot" color="#4E5BA6" className="hidden lg:flex w-2.5 h-2.5" />
          )}
        </div>
        <p className="font-medium text-xs text-grayLight-700">{event.title}</p>
      </div>
    );
  });

  const tooltipBody = () => (
    <div className="flex flex-col gap-spacing-xs w-52 z-0">
      {events.map((event, idx) => (
        <div
          key={idx}
          className="flex items-center border-b box-border border-gray-200 justify-between pb-1 z-0">
          <p className="font-medium text-xs text-grayLight-700 z-0">{event.title}</p>
          <div className="flex items-center gap-spacing-xs">
            {event.isInBasket && !!event.workingDay && (
              <>
                <IconComponent iconName="xCircle" color="#F79009" className="w-2.5 h-2.5" />
                <p className="font-medium text-warning-700" style={{ fontSize: '12px' }}>
                  Nieopłacone
                </p>
              </>
            )}
            {event.isFixed && !!event.workingDay && (
              <>
                <IconComponent iconName="checkCircle" color="#067647" className="w-2.5 h-2.5" />
                <p className="font-medium text-success-700" style={{ fontSize: '12px' }}>
                  Opłacone
                </p>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div
      className="custom-day-cell z-0"
      aria-disabled={disabled}
      style={{
        minHeight: '100px'
      }}>
      <div className="day-number">{date.getDate()}</div>
      <Tooltip
        hasArrow
        label={tooltipBody()}
        placement="top"
        bg="#fcfcfd"
        rounded="8px"
        padding="12px"
        color="black">
        <div className="events-list flex flex-col items-start">{eventList}</div>
      </Tooltip>
    </div>
  );
};

export default CustomDayCell;
