import { Input, Select } from "@chakra-ui/react";
import React, { useState, useEffect, SyntheticEvent } from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../context/AlertProvider";
import { useApi } from "../../../../context/ApiProvider";
import { OrganisationUnitInterface } from "../../../../shared";
import { ReportInterface } from "../../../../shared/type/report.type";

import './SingleReport.css';
import ChooseMonths, { MonthDateType } from "../../../ChooseMonths/ChooseMonths";
import IconComponent from "../../../IconComponent/IconComponent";

const formatDateToRequest = (date: string | undefined) => {
  if (!date) return "";
  const [year, month, day] = date.split("-");
  return `${day}-${month}-${year}`;
};

type DocumentFileOutputType = "PDF" | "XLSX";

const SingleReport = ({ report }: { report: ReportInterface }) => {
  const { apiTenantOrganisationUnitController, apiReportController } = useApi();
  const { id: tenantId } = useParams();
  const { setShow, setAlertProperties } = useAlertContext();

  const [organizationUnits, setOrganizationUnits] = useState<OrganisationUnitInterface[]>([]);
  const [selectedOrganizationUnitId, setSelectedOrganizationUnitId] = useState<number | null>(null);
  const [dateScope, setDateScope] = useState<{
    start: string | undefined;
    end: string | undefined;
  }>({
    start: undefined,
    end: undefined
  });
  const today = new Date();

  const [forMonthFrom, setForMonthFrom] = useState<MonthDateType>({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate()
  });

  const [forMonthTo, setForMonthTo] = useState<MonthDateType>({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate()
  });

  const [currentDate, setCurrentDate] = React.useState<MonthDateType>({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate()
  });

  const paramsBuilder = () => {
    let params = {};

    if (report.reportCriterion?.monthSelect) {
      params = {
        ...params,
        year: currentDate.year,
        month: currentDate.month
      };
    }

    if (report.reportCriterion?.unitSelect) {
      params = {
        ...params,
        orgId: selectedOrganizationUnitId
      };
    }

    if (report.reportCriterion?.dateScope) {
      params = {
        ...params,
        fromDate: formatDateToRequest(dateScope.start),
        toDate: formatDateToRequest(dateScope.end)
      };
    }

    if (report.reportCriterion?.monthScope) {
      params = {
        ...params,
        forYearFrom: forMonthFrom.year,
        forMonthFrom: forMonthFrom.month,
        forYearTo: forMonthTo.year,
        forMonthTo: forMonthTo.month
      };
    }

    return params;
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = event.target.value;
    if (dateScope.end && newStartDate > dateScope.end) {
      setAlertProperties({
        timeout: 5000,
        status: 'warning',
        title: 'Uwaga',
        description: 'Data początkowa musi być wcześniejsza niż data końcowa.'
      });
      setShow(true);
      return;
    }

    setDateScope((prev) => ({ ...prev, start: newStartDate }));
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = event.target.value;
    if (dateScope.start && newEndDate < dateScope.start) {
      setAlertProperties({
        timeout: 5000,
        status: 'warning',
        title: 'Uwaga',
        description: 'Data końcowa musi być późniejsza niż data początkowa.'
      });
      setShow(true);
      return;
    }

    setDateScope((prev) => ({ ...prev, end: newEndDate }));
  };

  const downloadReport = async (outputType: DocumentFileOutputType) => {
    const reportParams = paramsBuilder();

    try {
      const response = await apiReportController(report.apiUrl).get('', {
        params: {
          tenantId: tenantId,
          ...reportParams,
          outputType
        },
        responseType: 'blob'
      });

      const fileName = response.headers['content-disposition']?.split('filename=')[1];

      const blob = new Blob([response.data], {
        type: outputType === 'PDF' ? 'application/pdf' : 'application/vnd.ms-excel'
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${fileName}`;
      document.body.appendChild(link);
      link.click();

      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to download report:', error);
    }
  };

  const fetchOrganizationUnits = async () => {
    try {
      const response = await apiTenantOrganisationUnitController('').get(`/${tenantId}`);
      setOrganizationUnits(response.data);
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    if (report.reportCriterion?.unitSelect) {
      fetchOrganizationUnits();
    }
  }, []);

  useEffect(() => {
    const startMonth = new Date(forMonthFrom.year, forMonthFrom.month - 1, 1);
    const endMonth = new Date(forMonthTo.year, forMonthTo.month - 1, 1);

    if (startMonth > endMonth) {
      setAlertProperties({
        timeout: 5000,
        status: 'warning',
        title: 'Uwaga',
        description: 'Miesiąc końcowy nie może być wcześniejszy niż początkowy.'
      });
      return setShow(true);
    }

    setShow(false);
  }, [forMonthFrom, forMonthTo]);

  return (
    <form
      className="w-full flex"
      onSubmit={(e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
        e.preventDefault();
        const submitter = (e.nativeEvent as SubmitEvent).submitter as HTMLInputElement;

        const fileType = submitter?.value as DocumentFileOutputType;

        if (fileType === 'PDF' || fileType === 'XLSX') {
          downloadReport(fileType);
        }
      }}>
      <div className="basis-7/12">
        <p className="text-sm font-semibold text-grayLight-700">{report.title}</p>
      </div>
      <div className="basis-3/12 flex flex-col gap-spacing-lg">
        {report.reportCriterion?.monthSelect && (
          <ChooseMonths
            setDate={setCurrentDate}
            currentDate={currentDate}
            mode={'month'}
            hideTodayButton
          />
        )}
        {report.reportCriterion?.unitSelect && (
          <div style={{ width: '252px' }}>
            <Select
              required
              color="gray"
              onChange={(event) => {
                if (event.target.value === 'null') {
                  setSelectedOrganizationUnitId(null);
                  return;
                }
                setSelectedOrganizationUnitId(Number(event.target.value));
              }}>
              <option value="null">Wszystkie klasy</option>
              {organizationUnits.map((unit) => (
                <option key={unit.id} value={unit.id}>
                  {unit.name}
                </option>
              ))}
            </Select>
          </div>
        )}
        {report.reportCriterion?.dateScope && (
          <div className="w-64 flex items-center justify-between">
            <div className="w-28">
              <Input
                required
                color="gray"
                type="date"
                value={dateScope.start}
                onChange={handleStartDateChange}
              />
            </div>
            <p className="text-grayLight-700">-</p>
            <div className="w-28">
              <Input
                required
                color="gray"
                type="date"
                value={dateScope.end}
                onChange={handleEndDateChange}
              />
            </div>
          </div>
        )}
        {report.reportCriterion?.monthScope && (
          <div className="w-64 flex flex-col items-center justify-between">
            <div>
              <ChooseMonths
                setDate={setForMonthFrom}
                currentDate={forMonthFrom}
                mode={'month'}
                hideTodayButton
              />
            </div>
            <p className="text-grayLight-700">-</p>
            <div>
              <ChooseMonths
                setDate={setForMonthTo}
                currentDate={forMonthTo}
                mode={'month'}
                hideTodayButton
              />
            </div>
          </div>
        )}
      </div>
      <div className="basis-2/12 flex gap-spacing-md">
        <button
          type="submit"
          name="submitButton"
          value="PDF"
          className="flex gap-spacing-xs pt-spacing-md pr-spacing-lg pb-spacing-md pl-spacing-lg">
          <IconComponent iconName="pdf" />
          <p className="downloadButton">PDF</p>
        </button>
        <button
          type="submit"
          name="submitButton"
          value="XLSX"
          onClick={() => downloadReport('XLSX')}
          className="flex gap-spacing-xs pt-spacing-md pr-spacing-lg pb-spacing-md pl-spacing-lg">
          <IconComponent iconName="xls" />
          <p className="downloadButton">XLSX</p>
        </button>
      </div>
    </form>
  );
};

export default SingleReport;
