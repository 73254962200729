import {
  Thead, Th, Box, Text, TableContainer
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import React, { useState, useEffect } from "react";
import { useEditAdministratorContext } from "../../../../../../context/EditAdministratorProvider";
import { AvailableTenantInterface } from "../../../../../../shared/type/tenant.type";
import CustomChakraButton from "../../../../../CustomChakraButton/CustomChakraButton";
import { DataTable } from "../../../../../DataTable/DataTable";
import AssignNewBranch from "./AssignNewBranch/AssignNewBranch";

const AssignedBranches = () => {
  const columnHelper = createColumnHelper<AvailableTenantInterface>();
  const { fetchAdministrators, unassignTenant, administrator } = useEditAdministratorContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [assignedTenants, setAssignedTenants] = useState<AvailableTenantInterface[]>([]);
  const [showAssignBranchPopUp, setShowAssignBranchPopUp] = useState<boolean>(false);

  const syncAssignedTenants = async () => {
    setIsLoading(true);
    try {
      await fetchAdministrators();
      setAssignedTenants(administrator.assignedTenants);
    } catch (e) {
      console.error("Błąd odświeżania oddziałów:", e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    syncAssignedTenants();
  }, []);

  useEffect(() => {
    setAssignedTenants(administrator.assignedTenants);
  }, [administrator.assignedTenants]);

  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => {
        const { name } = info.row.original;
        return (
          <p className='text-sm text-grayLight-900 font-medium underline'>{name}</p>
        )
      },
      header: 'Nazwa oddziału',
    }),
    columnHelper.display({
      id: "actions",
      header: "",
      cell: (info) => {
        const { id } = info.row.original;

        return (
          <div className="flex gap-spacing-sm w-16">
            <button
              onClick={async () => {
                await unassignTenant(id);
                syncAssignedTenants();
              }}
              type="button"
              className="font-semibold text-sm text-grayLight-600 bg-transparent border-none"
            >
              Usuń
            </button>
          </div>
        );
      },
    }),
  ];

  return (
    <TableContainer h={800} overflowY="auto" className="Branches__table bg-white rounded-lg">
      <DataTable
        disableFilters={true}
        extraThead={
          <Thead>
            <Th colSpan={columns.length}>
              <div className="w-full flex items-center justify-between">
                <div className="flex flex-col basis-4/5">
                  <Box>
                    <Text
                      whiteSpace="normal"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      className="font-semibold text-base text-grayLight-900"
                    >
                      Przypisane oddziały
                    </Text>
                  </Box>
                </div>
                <div className="flex flex-row items-end gap-spacing-md">
                  <CustomChakraButton
                    size="md"
                    hierarchy="primary"
                    iconPosition="left"
                    icon="plus"
                    buttonProps={{
                      type: 'button',
                      onClick: () => setShowAssignBranchPopUp(true),
                    }}
                  >
                    Przypisz oddział
                  </CustomChakraButton>
                </div>
              </div>
            </Th>
          </Thead>
        }
        columns={columns}
        isLoading={isLoading}
        data={assignedTenants}
      />
      {showAssignBranchPopUp && (
        <AssignNewBranch
          isOpen={showAssignBranchPopUp}
          onClose={() => {
            syncAssignedTenants();
            setShowAssignBranchPopUp(false);
          }}
        />
      )}
    </TableContainer>
  );
};

export default AssignedBranches;
