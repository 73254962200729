import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import { Select } from "chakra-react-select";
import React, { useState, useEffect, useRef } from "react";
import { useApi } from "../../../context/ApiProvider";
import { useThemeContext } from "../../../context/ThemeProvider";
import { ParentSaldoInterface } from "../../../shared/type/parentSaldo.type";
import { TPayPayOfTheBalanceResponseInterface } from "../../../shared/type/tpayResponse.type";
import CustomChakraButton from "../../CustomChakraButton/CustomChakraButton";
import SaldoUserCard from "./SaldoUserCard/SaldoUserCard";

interface Props {
  isOpen: boolean,
  onClose: () => void,
}

const PayOffTheBalance = ({ isOpen, onClose }: Props) => {
  const { isMobile } = useThemeContext();
  const { apiParentPaySaldoController, apiParentPaymentController } = useApi();
  const [ selectedOrganizationUnitId, setSelectedOrganizationUnitId ] = useState<number | null>(null);
  const [ parentSaldoContext, setParentSaldoContext ] = useState<ParentSaldoInterface>();
  const [ tpayResponse, setTpayResponse ] = useState<TPayPayOfTheBalanceResponseInterface>();
  const [ consumerAmonut, setConsumerAmount ] = useState<{ consumerId: number, topUpAmount: string, }[]>([]);
  const hiddenFormRef = useRef<HTMLFormElement>(null);

  const fetchContext = async () => {
    try {
      const response: AxiosResponse<ParentSaldoInterface> = await apiParentPaySaldoController('').get('');
      setParentSaldoContext(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const updateConsumerAmount = (consumerId: number, amount: string) => {
    const amountCopy = [...consumerAmonut];
    const index = amountCopy.findIndex(item => item.consumerId === consumerId);

    if (index !== -1) {
      amountCopy[index] = { ...amountCopy[index], topUpAmount: amount };
    } else {
      amountCopy.push({ consumerId, topUpAmount: amount });
    }

    setConsumerAmount(amountCopy);
  };

  const confirmPayment = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      const response: AxiosResponse<TPayPayOfTheBalanceResponseInterface> = await apiParentPaymentController('make-a-top-up').post('', consumerAmonut);
      const { data, status } = response;

      if (status === 200) {
        setTpayResponse(data);
      } else {
        console.error('Payment failed with status:', status);
      }

    } catch (error) {
      console.error('Error during payment confirmation:', error);
    }
  };

  useEffect(() => {
    if (tpayResponse && tpayResponse.tpayPaymentDto && hiddenFormRef.current) {
      const submitButton = hiddenFormRef.current.querySelector('input[type="submit"]');
      if (submitButton) {
        // @ts-ignore
        submitButton.click();
      } else {
        console.error('Submit button not found in hidden form.');
      }
    }
  }, [tpayResponse]);

  useEffect(() => {
    if(parentSaldoContext?.tenants.length === 1){
      setSelectedOrganizationUnitId(parentSaldoContext.tenants[0].tenantId)
    }
  }, [parentSaldoContext]);

  useEffect(() => {
    if (parentSaldoContext && selectedOrganizationUnitId !== null) {
      const selectedTenant = parentSaldoContext.tenants.find(
        (tenant) => tenant.tenantId === selectedOrganizationUnitId
      );

      const consumerAmountTable = selectedTenant?.consumers.map((consumer) => ({
        consumerId: consumer.consumerId,
        topUpAmount: "0",
      })) || [];

      setConsumerAmount(consumerAmountTable);
    }
  }, [parentSaldoContext, selectedOrganizationUnitId]);

  useEffect(() => {
    fetchContext();
  }, []);

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={isMobile ? 'full' : '2xl'} isCentered>
      <form onSubmit={confirmPayment}>
        <ModalOverlay
          sx={{
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col gap-spacing-xl">
            <h2 className="font-semibold text-lg text-grayLight-900">Spłać saldo</h2>
            <p className="font-normal text-sm text-grayLight-600">Tutaj możesz dokonać spłaty salda. Po określeniu kwoty jaką chce się wpłacić dla wybranego konsumenta,
            system przekieruje do systemu płatniczego w celu sfinalizowania transakcji.
            Spłacanie salda może zostać wykonane w ratach, tzn. można spłacić część zadłużenia.
            Do podanej kwoty zostanie doliczona opłata za realizację płatności.
            </p>
            <div className="border-b border-grayLight-200" />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-xl">
            <div className="flex flex-col gap-spacing-md">
              <label className="text-sm font-medium text-grayLight-700">Wybierz
                szkołę</label>
              <Select
                colorScheme="gray"
                placeholder="Wybierz"
                value={
                  parentSaldoContext?.tenants
                    .map(tenant => ({ label: tenant.name, value: tenant.tenantId }))
                    .find(tenant => tenant.value === selectedOrganizationUnitId) || null
                }
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "white",
                  }),
                }}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setSelectedOrganizationUnitId(selectedOption.value);
                  }
                }}
                options={
                  parentSaldoContext?.tenants.map(unit => ({
                    label: unit.name,
                    value: unit.tenantId,
                  }))
                }
              />
            </div>
            <div className="flex flex-col gap-spacing-3xl">
              {selectedOrganizationUnitId === null ?
                <div className="flex items-center justify-center pt-spacing-3xl pb-spacing-3xl">
                  <p className="font-normal text-sm text-grayLight-600">Wybierz placówkę</p>
                </div> : (
                  parentSaldoContext?.tenants
                    .find((tenant: { tenantId: number }) => tenant.tenantId === selectedOrganizationUnitId)
                    ?.consumers.map((consumer) => (
                    <SaldoUserCard key={consumer.consumerId} consumer={consumer}
                                   updateConsumerAmount={updateConsumerAmount} />
                  ))
                )}
            </div>
          </ModalBody>
          <ModalFooter className="w-full flex flex-col-reverse lg:flex-row justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow lg:w-auto w-full",
                onClick: onClose
              }}
            >
              Anuluj
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow lg:w-auto w-full",
                type: "submit"
              }}
            >
              Wpłać
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>

      <form ref={hiddenFormRef} method="post" action={tpayResponse?.tpayPaymentDto.action}>
          <input type="hidden" name="amount" value={tpayResponse?.tpayPaymentDto.kwota} />
          <input type="hidden" name="description" value={tpayResponse?.tpayPaymentDto.opis} />
          <input type="hidden" name="crc" value={tpayResponse?.tpayPaymentDto.crc} />
          <input type="hidden" name="md5sum" value={tpayResponse?.tpayPaymentDto.md5sum} />
          <input type="hidden" name="result_url" value={tpayResponse?.tpayPaymentDto.result_url} />
          <input type="hidden" name="merchant_description" value={tpayResponse?.tpayPaymentDto.merchant_description} />
          <input type="hidden" name="return_url" value={tpayResponse?.tpayPaymentDto.return_url} />
          <input type="hidden" name="return_error_url" value={tpayResponse?.tpayPaymentDto.return_error_url} />
          <input type="hidden" name="email" value={tpayResponse?.tpayPaymentDto.email} />
          <input type="hidden" name="imie" value={tpayResponse?.tpayPaymentDto.imie} />
          <input type="hidden" name="nazwisko" value={tpayResponse?.tpayPaymentDto.nazwisko} />
          <input type="hidden" name="online" value="0" />
          <input type="hidden" name="language" value={tpayResponse?.tpayPaymentDto.language} />
          <input type="hidden" value={tpayResponse?.tpayPaymentDto.id} name="id" id="id" />
          <input type="submit" className="hidden" id="submit" />
        </form>
    </Modal>
);
};

export default PayOffTheBalance;
