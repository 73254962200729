import { Divider } from '@chakra-ui/react';
import { AxiosResponse, HttpStatusCode } from 'axios';
import React, { useState, useEffect } from 'react';
import { useAlertContext } from '../../../context/AlertProvider';
import { useApi } from '../../../context/ApiProvider';
import { useParentDashboardContextContext } from '../../../context/ParentDashboardContext';
import {
  ParentConsumerContextInterface,
  ParentConsumerUpdateInterface
} from '../../../shared/type/parentConsumerContext.type';
import sanitizePriceInput from '../../../utils/price/sanitizePriceInput';
import { ConsumerDto } from '../../../shared/type/parentDashboardContext.type';
import CustomChakraButton from '../../CustomChakraButton/CustomChakraButton';
import CustomHorizontalTabs from '../../CustomHorizontalTabs/CustomHorizontalTabs';

import ParentAddKidPopUp from '../ParentMain/AddKidNotification/ParentAddKidPopUp/ParentAddKidPopUp';

import './ParentAssignedAccounts.css';
import CheckboxInput from '../../Forms/CheckboxInput/CheckboxInput';
import FormInput from '../../Forms/FormInput/FormInput';
import StickyNotes from '../../StickyNotes/StickyNotes';

const ParentAssignedAccounts = () => {
  const { apiParentConsumerController } = useApi();
  const { setShow, setAlertProperties } = useAlertContext();
  const { parentDashboardContext, fetchParentDashboardContext } =
    useParentDashboardContextContext();
  const [customers, setCustomers] = useState<ConsumerDto[] | null>(null);
  const [consumerId, setConsumerId] = useState<number | null>(null);
  const [consumerUpdateContext, setConsumerUpdateContext] = useState<
    ParentConsumerUpdateInterface | undefined
  >(undefined);
  const [areButtonActive, setAreButtonsActive] = useState<boolean>(false);
  const [showAddKidPopUp, setShowAddKidPopUp] = useState(false);

  const findTenantIdByConsumerId = (consumerId: number): number | null => {
    const tenant = parentDashboardContext?.tenants.find((tenant) =>
      tenant.consumers.some((consumer) => consumer.consumerDto.id === consumerId)
    );
    return tenant ? tenant.tenantId : null;
  };

  const findTenantNameByConsumerId = (consumerId: number): string | null => {
    const tenant = parentDashboardContext?.tenants.find((tenant) =>
      tenant.consumers.some((consumer) => consumer.consumerDto.id === consumerId)
    );
    return tenant ? tenant.tenantName : null;
  };

  const fetchUpdateConsumerContext = async (id: number) => {
    try {
      const tenantId = findTenantIdByConsumerId(id) || 0;
      const response: AxiosResponse<ParentConsumerContextInterface> =
        await apiParentConsumerController('payment-context').get(`${tenantId}/${id}`);
      setConsumerUpdateContext(response.data.consumerUpdateDto);
    } catch (error) {
      console.error('Failed to fetch consumer context:', error);
    }
  };

  const updateConsumer = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    let allowedToBuy, creditLimitMoneyString, remarks, cardNumber;
    if (consumerUpdateContext) {
      allowedToBuy = consumerUpdateContext.allowedToBuy;
      creditLimitMoneyString = consumerUpdateContext.creditLimitMoneyString;
      remarks = consumerUpdateContext.remarks;
      cardNumber = consumerUpdateContext.cardNumber;
    }

    const tenantId = findTenantIdByConsumerId(Number(consumerId));

    try {
      const response = await apiParentConsumerController('update').put(`/${tenantId}`, {
        consumerId,
        allowedToBuy,
        cardNumber,
        creditLimitMoneyString,
        remarks
      });

      if (response.status) {
        setAlertProperties({
          timeout: 2000,
          title: 'Sukces',
          description: `Poprawnie zaktualizowano użytkownika`,
          status: 'success'
        });
        fetchParentDashboardContext();
        setShow(true);
      }
    } catch (error: any) {
      if (error.response.status === HttpStatusCode.Conflict) {
        setAlertProperties({
          timeout: 5000,
          title: 'Konflikt',
          description: `Błąd: ${error.response.data.errorMessage}`,
          status: 'warning'
        });
      } else {
        setAlertProperties({
          timeout: 5000,
          title: 'Błąd',
          description: `Błąd aktualizacji użytkownika - ${error.response.data.violations[0].fieldName}: ${error.response.data.violations[0].message}`,
          status: 'error'
        });
      }
      setShow(true);
    }
  };

  const getAllConsumers = () => {
    if (!parentDashboardContext || !parentDashboardContext.tenants) {
      return [];
    }

    return parentDashboardContext.tenants.flatMap((tenant) => {
      if (tenant.consumers && Array.isArray(tenant.consumers)) {
        return tenant.consumers.map((consumer) => consumer.consumerDto);
      }
      return [];
    });
  };

  useEffect(() => {
    if (
      consumerUpdateContext?.creditLimitMoneyString === null ||
      consumerUpdateContext?.creditLimitMoneyString === ''
    ) {
      setConsumerUpdateContext((prev) => {
        if (!prev) return undefined;

        return {
          ...prev,
          creditLimitMoneyString: '0,00'
        };
      });
    }
  }, [consumerUpdateContext?.creditLimitMoneyString]);

  useEffect(() => {
    const consumers = getAllConsumers();
    setCustomers(consumers);

    if (consumers.length > 0) {
      const defaultConsumerId = consumers[0].id;
      setConsumerId(defaultConsumerId);
    }
  }, [parentDashboardContext]);

  useEffect(() => {
    if (consumerId !== null) {
      fetchUpdateConsumerContext(consumerId);
    }
  }, [consumerId]);

  const selectedConsumer = customers?.find((consumer) => consumer.id === consumerId);

  return (
    <section className="ParentAssignedAccounts flex flex-col gap-spacing-3xl lg:pr-2.5">
      <div className="pt-spacing-3xl flex flex-row justify-between items-center  pr-spacing-3xl pb-spacing-xl pl-spacing-3xl">
        <h1 className="p-0 m-0 font-semibold text-grayLight-900" style={{ fontSize: '30px' }}>
          Twoi konsumenci
        </h1>
        <div className="lg:w-auto w-full">
          <CustomChakraButton
            size="lg"
            hierarchy="primary"
            iconPosition="left"
            icon="plus"
            iconColor="#292524"
            buttonProps={{
              className: 'lg:w-auto w-full',
              onClick: () => {
                setShowAddKidPopUp(true);
              }
            }}>
            Dodaj dziecko
          </CustomChakraButton>
        </div>
      </div>
      <div className="flex flex-col pt-spacing-3xl lg:px-spacing-xl pb-spacing-3xl gap-spacing-xl">
        <CustomHorizontalTabs
          onChange={(value) => setConsumerId(value)}
          data={
            customers &&
            customers.map((consumer) => ({
              value: consumer.id,
              label: `${consumer.firstName} ${consumer.lastName}`
            }))
          }
        />
        <div className="ParentAssignedAccounts__kidTab w-full flex flex-col border-b box-border border-brand-200 lg:border-transparent gap-spacing-xl lg:gap-0 p-spacing-xl lg:pt-spacing-xl lg:pb-spacing-xl lg:px-spacing-3xl lg:rounded-xl">
          <div className="flex flex-col gap-spacing-xl lg:gap-0 lg:flex-row lg:justify-between">
            <div>
              <p className="font-semibold text-grayLight-700" style={{ fontSize: '18px' }}>
                {selectedConsumer
                  ? `${selectedConsumer.firstName} ${selectedConsumer.lastName}`
                  : '-'}
              </p>
            </div>
            <div className="flex flex-col items-start lg:items-end gap-spacing-xs">
              <p className="text-sm font-normal text-grayLight-600">Saldo</p>
              <p className="font-semibold text-grayLight-600" style={{ fontSize: '18px' }}>
                {selectedConsumer ? `${selectedConsumer.saldo}` : '-'}
              </p>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col gap-spacing-xl">
            <div className="flex flex-col">
              <p className="text-sm font-normal text-grayLight-600">Klasa</p>
              <p className="font-semibold text-grayLight-600" style={{ fontSize: '18px' }}>
                {selectedConsumer ? `${selectedConsumer.organisationUnitName}` : '-'}
              </p>
            </div>
            <div className="flex flex-col">
              <p className="text-sm font-normal text-grayLight-600">Szkoła</p>
              <p className="font-semibold text-grayLight-600" style={{ fontSize: '18px' }}>
                {findTenantNameByConsumerId(consumerId || 0) || '-'}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-spacing-md">
        <Divider className="basis-1/2" />
        <p className="text-nowrap text-sm font-medium text-grayLight-600">Edytuj profil</p>
        <Divider className="basis-1/2" />
      </div>
      <form
        onSubmit={updateConsumer}
        className="flex flex-col gap-spacing-6xl pl-spacing-3xl pr-spacing-3xl">
        <div className="flex flex-col lg:flex-row gap-spacing-xl">
          <div className="w-full lg:basis-1/2">
            <FormInput
              label="Identyfikator"
              placeholder="Wpisz numer identyfikatora"
              inputParams={{
                className: 'w-full',
                onChange: (e) => {
                  const target = e.target as HTMLInputElement;
                  const value = target.value.replace(/\D/g, '');
                  setAreButtonsActive(true);
                  setConsumerUpdateContext((prev) => {
                    if (!prev) return undefined;

                    return {
                      ...prev,
                      cardNumber: value
                    };
                  });
                },
                pattern: '\\d{10}',
                title:
                  'Numer identyfikatora musi składać się z dokładnie 10 cyfr lub może być pusty.',
                maxLength: 10,
                value: consumerUpdateContext?.cardNumber
              }}
              bottomElement={
                <p className="font-normal text-sm text-grayLight-600">
                  10-cyfrowy numer znajdziesz na karcie lub breloku otrzymanym w szkole
                </p>
              }
            />
          </div>
          <div className="w-full lg:basis-1/2 justify-end">
            <div className="hidden lg:flex justify-end gap-spacing-3xl">
              <CustomChakraButton
                iconPosition="onlyText"
                hierarchy="tertiaryColor"
                size="md"
                buttonProps={{
                  onClick: () => {
                    fetchUpdateConsumerContext(Number(consumerId));
                    setAreButtonsActive(false);
                  },
                  type: 'button',
                  disabled: !areButtonActive
                }}>
                Anuluj
              </CustomChakraButton>
              <CustomChakraButton
                iconPosition="onlyText"
                hierarchy="secondaryColor"
                size="md"
                buttonProps={{
                  type: 'submit',
                  disabled: !areButtonActive
                }}>
                Zapisz zmiany
              </CustomChakraButton>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-spacing-xl">
          <div className="flex flex-col basis-1/2">
            <label className="font-medium text-sm text-grayLight-700">Uwagi</label>
            <textarea
              cols={15}
              rows={5}
              placeholder="Wpisz uwagi na temat alergenów dziecka"
              className="ParentAssignedAccounts__textArea bg-white border border-grayLight-300 pt-spacing-lg pb-spacing-lg rounded-xl resize-none"
              value={consumerUpdateContext?.remarks}
              onChange={(e) => {
                setAreButtonsActive(true);
                setConsumerUpdateContext((prev) => {
                  if (!prev) return undefined;

                  return {
                    ...prev,
                    remarks: e.target.value
                  };
                });
              }}
              style={{ paddingRight: '14px', paddingLeft: '14px' }}
            />
          </div>
          <div className="basis-1/2">
            <label className="font-medium text-sm text-grayLight-700 text-transparent">Uwagi</label>
            <StickyNotes message="W to pole wpisz tylko poważne uwagi w celu zadbania o zdrowie dziecka np. alergie pokarmowe o przebiegu zagrażającym życiu. Pomoże to zadbać o zdrowie dziecka jeśli przeoczysz jakiś składnik w zamówieniu." />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-spacing-xl">
          <div className="flex flex-col basis-1/2 gap-spacing-xl">
            <CheckboxInput
              checkboxParams={{
                onChange: () => {
                  setAreButtonsActive(true);
                  setConsumerUpdateContext((prev) => {
                    if (!prev) return undefined;

                    return {
                      ...prev,
                      allowedToBuy: !consumerUpdateContext?.allowedToBuy
                    };
                  });
                },
                isChecked: consumerUpdateContext?.allowedToBuy
              }}
              checkboxPosition="items-center"
              customTextSize="16px"
              text="Zezwalam konsumentowi na samodzielny zakup posiłków na kredyt podczas wydawania posiłków."
            />
            {consumerUpdateContext?.allowedToBuy && (
              <FormInput
                label=""
                placeholder="Wpisz limit kretytu"
                inputParams={{
                  type: 'text',
                  value: sanitizePriceInput(consumerUpdateContext?.creditLimitMoneyString || ''),
                  onChange: (e) => {
                    setAreButtonsActive(true);
                    setConsumerUpdateContext((prev) => {
                      if (!prev) return undefined;

                      return {
                        ...prev,
                        creditLimitMoneyString: e.target.value
                      };
                    });
                  }
                }}
              />
            )}
          </div>
          <div className="basis-1/2">
            <StickyNotes message="Uruchomienie tej opcji pozwoli konsumentowi na zakup posiłków podczas wydawania. Określenie limitu kredytowego pozwoli na realizowanie zakupu do wskazanego poziomu zadłużenia." />
          </div>
        </div>
        <div className="flex flex-col lg:hidden px-spacing-xl pb-spacing-9xl gap-spacing-lg">
          <CustomChakraButton
            iconPosition="onlyText"
            hierarchy="primary"
            size="md"
            buttonProps={{
              type: 'submit',
              disabled: !areButtonActive
            }}>
            Zapisz zmiany
          </CustomChakraButton>
          <CustomChakraButton
            iconPosition="onlyText"
            hierarchy="tertiaryColor"
            size="md"
            buttonProps={{
              onClick: () => {
                fetchUpdateConsumerContext(Number(consumerId));
                setAreButtonsActive(false);
              },
              type: 'button',
              disabled: !areButtonActive
            }}>
            Anuluj
          </CustomChakraButton>
        </div>
      </form>
      {showAddKidPopUp && (
        <ParentAddKidPopUp
          onClose={() => {
            setShowAddKidPopUp(false);
            fetchParentDashboardContext();
          }}
          isOpen={showAddKidPopUp}
        />
      )}
    </section>
  );
};

export default ParentAssignedAccounts;
